<script setup>

const backend = useBackend();
const route = useRoute();

const {data: seo} = await useAsyncData(() => new Promise(done => {

  const empty = {
    available: false,
    header1: '',
    header2: '',
    header3: '',
    header4: '',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    imagin_url: ''
  };

  const brandName = route.name === 'brand' ? route.params.brand : null;

  if (!brandName) {
    done(empty);
    return;
  }

  backend.getBrandSeo(brandName).then(data => {
    const brandSeoData = data.data;

    if (brandSeoData && brandSeoData.header1) {
      brandSeoData.available = true;
      done(brandSeoData)
    } else {
      done(empty);
    }
  });

}));

const brands = await backend.getBrands();
const brandLogo = computed(() => {
  const brand = brands.data.find(brand => brand.name.toLowerDashed() === route.params.brand.toLowerDashed());
  return brand?.svg
})

</script>

<template>
  <div v-if="seo.available">
    <h2 class="w-full text-center text-2xl font-medium">{{ seo.header1 }}</h2>
    <div class="flex flex-col md:flex-row">
      <div class="flex justify-center items-center w-full md:min-w-[194px] md:max-w-[194px]">
        <div class="p-6 w-full flex justify-center">
          <img :src="brandLogo" class="h-32" :alt="'Leasing ' + route.params.brand.upperCaseFirst()" />
        </div>
      </div>
      <div class="grow col-span-1 lg:col-span-2 flex flex-col justify-center ">
        <SeoContentBlock :data="{content:seo.col1}" />
      </div>
    </div>
    <StaticSeparator class="w-auto" />
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
      <SeoContentBlock class='md:col-span-2' :data="{title:seo.header2,content:seo.col2}" />
      <div class="flex justify-center items-center">
        <NuxtImg v-if="seo?.imagin_url" :src="seo?.imagin_url" :alt="route.params.brand.upperCaseFirst() + ' leasing'" />
      </div>
    </div>
    <StaticSeparator class="w-auto" />
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
      <SeoContentBlock :data="{title:seo.header3,content:seo.col3}" />
      <div class="-mx-4 sm:mx-0">
        <SeoContentBlock :data="{title:seo.header4,content:seo.col4}" gray small />
      </div>
    </div>
  </div>
</template>

