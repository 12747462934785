import validate from "/srv/portal/node_modules/nuxt/dist/pages/runtime/validate.js";
import trailingslash_45global from "/srv/portal/middleware/trailingslash.global.js";
import manifest_45route_45rule from "/srv/portal/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  trailingslash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "catch-all": () => import("/srv/portal/middleware/catchAll.js")
}