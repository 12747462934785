<template>
    
<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32.1069 62.144C15.6376 62.144 2.24023 48.7466 2.24023 32.2773C2.24023 15.808 15.6376 2.41064 32.1069 2.41064C48.5762 2.41064 61.9736 15.808 61.9736 32.2773C61.9736 48.7466 48.5762 62.144 32.1069 62.144ZM32.1069 3.47731C16.2136 3.47731 3.3069 16.4053 3.3069 32.2773C3.3069 48.1493 16.2136 61.0773 32.1069 61.0773C48.0002 61.0773 60.9069 48.1707 60.9069 32.2773C60.9069 16.384 47.9789 3.47731 32.1069 3.47731Z" fill="#3B61AB"/>
<path d="M32.1076 59.0082C46.8706 59.0082 58.8383 47.0405 58.8383 32.2775C58.8383 17.5146 46.8706 5.54688 32.1076 5.54688C17.3447 5.54688 5.37695 17.5146 5.37695 32.2775C5.37695 47.0405 17.3447 59.0082 32.1076 59.0082Z" fill="url(#paint0_linear_8778_270265)"/>
<path d="M15.084 28.5439H19.3933C20.9293 28.5439 22.4653 29.0133 23.7667 29.8453L31.2973 34.9226C31.6813 35.1573 32.108 35.3493 32.5347 35.3919" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M13.8887 28.5439H18.966" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M32.5547 35.4131L37.312 36.1384C38.8907 36.3731 40.384 37.1411 41.3014 38.4424C41.8347 39.1678 42.112 39.9784 42.112 40.7891V41.3224C42.112 43.0078 40.768 44.3518 39.0827 44.3518H38.464" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M14.123 44.373H24.8324" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M14.123 35.3066L32.5551 35.4133" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M35.8615 44.373C35.8615 46.5704 34.0908 48.3624 31.8721 48.3624C29.6535 48.3624 27.8828 46.5917 27.8828 44.373" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M35.8615 44.3731C35.8615 42.1758 34.0908 40.3838 31.8721 40.3838C29.6535 40.3838 27.8828 42.1545 27.8828 44.3731" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M16.4277 28.5439V35.3066" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M38.2515 32.1066C37.5262 32.192 36.8222 32.192 36.1822 32.1066C33.4515 31.6373 31.2329 29.376 30.8062 26.6453C30.7209 25.984 30.7209 25.344 30.8062 24.6826" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M38.3573 27.733C38.08 27.7757 37.7386 27.7757 37.504 27.733C36.8853 27.6477 36.3946 27.349 35.968 26.9224C35.584 26.4957 35.3066 25.9624 35.2 25.429C35.1573 25.1517 35.1573 24.853 35.2 24.5757" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M38.0602 29.9945C37.7189 30.0372 37.3989 30.0372 37.0575 29.9945C35.9482 29.8452 34.9882 29.3332 34.2842 28.5652C33.5589 27.7972 33.0469 26.7945 32.9402 25.8345C32.8975 25.4932 32.8975 25.2158 32.9402 24.8745" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M41.6645 18.5813L38.2085 22.9973C38.0165 23.232 38.0165 23.5307 38.2085 23.7227L39.1685 24.6827C39.3605 24.8747 39.6378 24.8747 39.8938 24.6827L44.3098 21.2267C44.5445 21.0347 44.5018 20.6933 44.3098 20.5013L42.3471 18.5387C42.1978 18.3893 41.8778 18.3467 41.6858 18.5813H41.6645Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.4747 12.0532L31.2534 15.2745C31.0614 15.4665 31.0614 15.8078 31.2534 15.9998L35.52 20.2665C35.904 20.6505 38.6774 20.9918 39.6374 21.1838L40.4907 20.0745C40.3414 19.0718 39.9574 16.7678 39.488 16.2772L35.2214 12.0105C34.9867 11.8612 34.6454 11.8612 34.4534 12.0532H34.4747Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M50.8798 28.4583L47.6584 31.6796C47.4664 31.8716 47.1251 31.8716 46.9331 31.6796L42.6238 27.4556C42.2398 27.0716 41.7704 24.3836 41.6211 23.4236L42.7731 22.5703C43.7758 22.7196 46.1224 23.0396 46.6131 23.5303L50.8798 27.797C51.0718 27.9463 51.0718 28.2663 50.8798 28.4583Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_8778_270265" x1="5.37695" y1="32.2775" x2="58.817" y2="32.2775" gradientUnits="userSpaceOnUse">
<stop stop-color="#65B1FF"/>
<stop offset="1"/>
</linearGradient>
</defs>
</svg>

</template>