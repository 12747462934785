<script setup>
const props = defineProps({
  noOuterPaddings: Boolean,
  bgColor: {
    type: String,
    default: () => ''
  }
})
const backend = useBackend();
const backendStore = useBackendStore();
const carCount = backendStore.carCount.value;
const formattedCarCount = computed(() =>
  new Intl.NumberFormat('sk-SK').format(carCount)
)
const refSlideControl = ref();
const refSlideNavigation = ref();

const {data: brands} = await useAsyncData(() => new Promise(done => {
  backend.getBrands().then(result => {
    done(result.data);
  })
}))
const scrollToSlide = (slide) => {
    refSlideControl.value.scrollToSlide(slide);
    }
</script>
<template>
    <div>
        <h2 class="mb-12 text-azure-dark text-4xl text-center">
            Wybieraj spośród {{formattedCarCount}} samochodów wszystkich marek
        </h2>
        <StaticCarousel  disableControlClass
        >
                    <ButtonBrand 
                        v-for="brand in brands"
                        :name=brand.name
                        :alt="`Leasing ${brand.name}`"
                        :icon=brand.svg
                        :href="{name: 'brand', params: {brand: brand.name.toLowerDashed()}}"
                    />
        </StaticCarousel>
        <ButtonCall customContent :show-working-hours="false" :azure="true">
            <div class="bg-azure mt-2 rounded font-light text-center mx-auto max-w-[186px] text-base text-white px-4 py-2 cursor-pointer">
                Zapytaj o ofertę
            </div>
        </ButtonCall>
    </div>
</template>