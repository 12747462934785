<script setup>
definePageMeta({
  layout: "plain"
})

const badRoutes = useBadRoutes();
const backend = useBackend();
const route = useRoute();
const paymentPopup = ref();

const {data: liczakOffer, error} = await useAsyncData(() => new Promise(async (resolve, fail) => {
  const response = await backend.getCarLiczakOffer(route.params.uuid);
  if (response.data.status === 404) {
    return fail(response.data);
  }
  resolve(response.data.data);
}))
if (error.value) {
  // not works on dev, only on production!
  await badRoutes.handle()
}
const pdfMode = computed(() => !!route.query.pdf || false);
const moreEquipment = ref(false);
const brand = computed(() => liczakOffer.value ? liczakOffer.value.car.brandName : "");
const model = computed(() => liczakOffer.value ? liczakOffer.value.car.modelName : "");
const version = computed(() => liczakOffer.value ? liczakOffer.value.car.versionName : "");
const hasAdditionalEquipment = computed(() =>
  !!liczakOffer.value?.car?.additional_equipment && Object.keys(liczakOffer.value.car.additional_equipment).length > 0
);

const additionalEquipments = computed(() => liczakOffer.value?.car?.additional_equipment || {});

const equipment = computed(() => {
  const rebuilt = {};
  if (!liczakOffer.value.car.equipment) {
    return rebuilt;
  }

  liczakOffer.value.car.equipment.forEach(eq => {
    if (!rebuilt[eq.category]) {
      rebuilt[eq.category] = [];
    }
    rebuilt[eq.category].push(eq.name);
  });

  return Object.fromEntries(
    Object.entries(rebuilt)
      .sort((a, b) => {
        if (a[0] === "Pozostałe") return 1;
        if (b[0] === "Pozostałe") return -1;
        if (a[0] < b[0]) return -1;
        if (a[0] > b[0]) return 1;
        return 0;
      })
  );
})

useHead({
  title: `${brand.value.toUpperCase()} ${model.value.toUpperCase()} - oferta leasingu`,
  meta: [
    {name: 'description', content: `${brand.value.toUpperCase()} ${model.value.toUpperCase()} w leasingu dla przedsiębiorców i konsumentów ✔️ Decyzja leasingowa w 15 min ✔️ Atrakcyjne rabaty ✔️ Sprawdź!`},
    {name: 'robots', content: 'noindex, follow'}
  ],
  link: [
    {rel: 'canonical', href: 'https://vehis.pl' + route.path}
  ]
});

const request = useRequestURL();
const domain = request.href;

const isAbsolute = computed(() => {
  return domain.includes('?pdf=1');
});
const isCarkulacja = computed(() => {
  return domain.includes('carkulacja');
});
const drivePlusHeading = computed(() => {
  return domain.includes('carkulacja') ? 'Twój samochód ochroni pakiet DRIVE+' : 'W leasingu VEHIS Twój samochód ochroni pakiet DRIVE+';
})
const isAdditionalFeesVisible = computed(() => {
  return !!(
    liczakOffer.value?.offer?.additionalFees?.annualFee ||
    liczakOffer.value?.offer?.additionalFees?.costReimbursement ||
    liczakOffer.value?.offer?.additionalFees?.additionalInsuranceCost
  );
});
const isNew = computed(() => {
  return liczakOffer?.value.car.state === 'Nowy';
})

const drivePlusItems = [
  {
    icon: "VectorGainAssistanceCircle",
    text: "Assistance",
    subtext: "na Polskę i Europę",
    requiresAssistance: true,
  },
  {
    icon: "VectorGainAppCircle",
    text: "Serwisowanie samochodu z rabatami",
    subtext: "dzięki aplikacji mobilnej",
  },
  {
    icon: "VectorGainSupportCircle",
    text: "Wsparcie w procesie obsługi i rozliczenia szkód komunikacyjnych",
  },
  {
    icon: "VectorGainGpsCircle",
    text: "GPS wraz z aplikacją",
    subtext: " bezpieczeństwo i zarządzanie samochodem",
  },
];

const insuranceItems = [
  {
    icon: "IconAwardCircle",
    text: "Renomowani ubezpieczyciele",
    subtext: "PZU, Ergo Hestia, Axa, Benefia",
  },
  {
    icon: "IconCarInsuranceCircle",
    text: "Pakiet OC/AC/NNW",
    subtext: "z gwarancją niezmienności składki przez wiele lat",
  },
  {
    icon: "IconGapCircle",
    text: "Ubezpieczenie GAP",
    subtext: "pokrywające różnicę między odszkodowaniem z OC/AC a wartością samochodu z dnia zakupu",
  },
];

const scrolledFeesDescription = reactive({
  registration: false,
  administrationFee: false
})
</script>

<template>
  <AppHeaderMinimal :is-absolute="isAbsolute" :custom-logo="isCarkulacja" no-button-call no-link>
    <template #custom-logo-html>
      <VectorCarkulacjaLogo v-if="isCarkulacja && !liczakOffer?.logo" class="w-32 md:w-auto" />
      <img v-else-if="isCarkulacja && liczakOffer?.logo" class="w-32 md:w-auto md:ml-8 block h-20 p-2" :src="liczakOffer?.logo" alt="logo">
    </template>
    <template #button>
      <div class="py-2 text-right text-sm sm:text-left sm:text-base">
        Kalkulacja finansowania samochodu
      </div>
    </template>
  </AppHeaderMinimal>

  <PopupPayment ref="paymentPopup" :payment-uuid="liczakOffer?.payment?.paymentUuid" />

  <Container no-paddings>
    <LayoutColumns with-print>
      <LayoutMainColumn noflex class="space-y-16">
        <div class="relative">
          <div :class="{'lg:pb-6': isNew}"
            class="shadow-box print:shadow-none print:border print:border-gray-300 -ml-4 -mr-4 sm:-mx-8 bg-gray-100 container-sized rounded-b-lg overflow-hidden">
            <div class="bg-gradient-to-t from-30% from-white via-gray-200 via-40% to-white to-100%">
              <div class="px-6 sm:px-8">
                <CarOfferPreview :header="`${brand} ${model}`" :subheader="version" :car="liczakOffer?.car"
                                 :offer="liczakOffer?.offer" liczak-view />
                  <p v-if="isNew" class="text-xs font-extralight absolute bottom-6 z-20 hidden lg:block">
                    Prezentowana grafika samochodu ma jedynie charakter poglądowy.<br/>
                    Rzeczywisty wygląd, a w szczególności kolor i wersja wyposażenia mogą odbiegać od prezentowanego na grafice.
                </p>
              </div>
            </div>
          </div>
        </div>
        <template v-if="liczakOffer?.payment?.status && !pdfMode">
          <Panel class="p-6">
            <div v-if="liczakOffer?.payment?.status === 1" class="flex w-full h-full items-center">
              <div class="grow">Nie daj się wyprzedzić!</div>
              <div>
                <ButtonRounded class="max-w-96" color="celadon" @click="paymentPopup.openPopup()">
                  Zarezerwuj samochód
                </ButtonRounded>
              </div>
            </div>
            <div v-else-if="liczakOffer?.payment?.status === 2">
              <EffectSpinLoader />
              <div class="text-center">
                Twoja płatność jest w trakcie procesowania.
              </div>
            </div>
            <div v-else-if="liczakOffer?.payment?.status === 3">
              <div class="flex justify-center pb-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-10">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                </svg>
              </div>
              <div class="text-center">
                Kaucja rezerwacyjna wpłacona.
              </div>
              <div class="font-extralight pt-8">
                <p>Szanowni Państwo, potwierdzamy wpłatę kaucji rezerwacyjnej i zawarcie tym samym umowy rezerwacyjnej na warunkach zawartych w regulaminie, który wysłaliśmy na podany adres mailowy.</p>
              </div>
            </div>
            <div v-else>
              <div class="flex justify-center pb-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-10">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                </svg>
              </div>
              <div class="text-center">
                Wystąpił problem podczas procesowania płatności.
              </div>
              <div class="font-extralight pt-8">
                {{ liczakOffer?.payment?.offerMessage }}
              </div>
            </div>
          </Panel>
        </template>
        <div v-if="liczakOffer?.offer?.isFormEnabled || liczakOffer?.offer?.isUploadEnabled" class="page-break-padded" />
            <div class="relative" >
              <FormLiczakApplication :clientType="liczakOffer?.offer?.client" :operatorData="{owner: liczakOffer?.offer?.owner, email: liczakOffer?.offer?.email, phone: liczakOffer?.offer?.phone }" :files="liczakOffer?.offer?.files" :isFormEnabled="liczakOffer?.offer?.isFormEnabled" :isUploadEnabled="liczakOffer?.offer?.isUploadEnabled" :isAgreementsChecked="liczakOffer?.offer?.agreementsChecked"/>
          </div>
        <Panel class="p-6">
          <OfferHeader :header-right-class="'text-sm font-extralight'" :right="`WIBOR ${liczakOffer?.offer.wibor}%`">
            Kalkulacja finansowania
          </OfferHeader>
          <OfferTableRow no-separator>
            <OfferTableField type="label" />
            <OfferTableField class="hidden lg:block">udział [%]</OfferTableField>
            <OfferTableField>ilość rat</OfferTableField>
            <OfferTableField>netto</OfferTableField>
            <OfferTableField>brutto</OfferTableField>
          </OfferTableRow>
          <OfferTableRow>
            <OfferTableField class="pl-2 sm:pl-6" type="label">opłata wstępna</OfferTableField>
            <OfferTableField class="hidden lg:block">{{ liczakOffer?.offer.initialFee.value }}</OfferTableField>
            <OfferTableField>1</OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.initialFee.net }}</OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.initialFee.gross }}</OfferTableField>
          </OfferTableRow>
          <OfferTableRow>
            <OfferTableField class="pl-2 sm:pl-6"  type="label">wartość wykupu</OfferTableField>
            <OfferTableField class="hidden lg:block">{{ liczakOffer?.offer.repurchase.value }}</OfferTableField>
            <OfferTableField>1</OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.repurchase.net }}</OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.repurchase.gross }}</OfferTableField>
          </OfferTableRow>
          <OfferTableRow class="text-azure" no-separator>
            <OfferTableField class="pl-2 sm:pl-6"  type="label">Twoja rata {{liczakOffer?.offer.client === 'consumer' ? 'finansowa' : 'leasingowa'}}</OfferTableField>
            <OfferTableField class="hidden lg:block " />
            <OfferTableField :large="liczakOffer?.offer.client === 'consumer'" >
              {{ liczakOffer?.offer.installment.amount }}
            </OfferTableField>
            <OfferTableField :large="liczakOffer?.offer.client === 'entrepreneur'" >
              {{ liczakOffer?.offer.installment.net[liczakOffer?.offer.installment.amount] }}
            </OfferTableField>
            <OfferTableField> {{ liczakOffer?.offer.installment.gross[liczakOffer?.offer.installment.amount] }}
            </OfferTableField>
          </OfferTableRow>
          <OfferTableRow no-separator class="pl-2 sm:pl-6" >
            <div class="flex flex-wrap justify-between w-full mt-6">
              <div class="w-1/2 lg:w-auto lg:flex-grow-0 pl-0 lg:p-2 lg:pl-0" v-if="liczakOffer?.offer.financingOffer.financingForm">
                <p class="font-extralight text-xs">forma finansowania</p>
                <p class="text-sm font-light lowercase">{{ liczakOffer?.offer.financingOffer.financingForm }}</p>
              </div>
              <div class="w-1/2 lg:w-auto lg:flex-grow-0 p-2 pl-0 pt-0 lg:pt-2" v-if="liczakOffer?.offer.financingOffer.contractPeriod">
                <p class="font-extralight text-xs">okres umowy</p>
                <p class="text-sm font-light text-up lowercase">{{ liczakOffer?.offer.financingOffer.contractPeriod }}</p>
              </div>
              <div class="w-1/2 lg:w-auto lg:ml-auto pt-2 lg:p-2" v-if="liczakOffer?.offer.financingOffer.installmentsSum">
                <p class="font-extralight text-xs">suma rat leasingowych</p>
                <p class="text-sm font-light lowercase">{{ liczakOffer?.offer.financingOffer.installmentsSum }}%</p>
              </div>
            </div>
          </OfferTableRow>
        </Panel>
        <Panel v-if="!liczakOffer?.offer.hideInsurance || (liczakOffer?.offer.client !== 'consumer' && liczakOffer?.offer.showDrivePlus)" class="p-6">
          <OfferHeader v-if="!liczakOffer?.offer.hideInsurance" :header-right-class="'text-sm font-extralight max-w-[326px] md:text-right 2xl:max-w-full'"
                       :right="liczakOffer?.offer.insuranceTableLabel">Kalkulacja ubezpieczenia
          </OfferHeader>
          <OfferTableRow no-separator v-if="!liczakOffer?.offer.hideInsurance && !liczakOffer?.offer.hideInsuranceInstallment">
            <OfferTableField class="pl-2 sm:pl-6"  type="label" />
            <OfferTableField></OfferTableField>
            <OfferTableField>ilość rat</OfferTableField>
            <OfferTableField>netto</OfferTableField>
            <OfferTableField>brutto</OfferTableField>
          </OfferTableRow>

          <template v-if="!liczakOffer?.offer.hideInsurance && liczakOffer?.offer.insurance.firstOption.installment.amount && !liczakOffer?.offer.hideInsuranceInstallment">
            <OfferTableRow v-for="(value, amount, idx) in liczakOffer?.offer.insurance.firstOption.installment.value" :no-separator="idx !== Object.keys(liczakOffer?.offer.insurance.firstOption.installment.value).length - 1">
              <OfferTableField class="pl-2 sm:pl-6"  type="label">{{ !idx ? liczakOffer?.offer.insurance.firstOption.installment.label : "" }}</OfferTableField>
              <OfferTableField></OfferTableField>
              <OfferTableField>{{ amount }}</OfferTableField>
              <OfferTableField>{{ value.net }}</OfferTableField>
              <OfferTableField>{{ value.gross }}</OfferTableField>
            </OfferTableRow>
          </template>

          <template v-if="liczakOffer?.offer.hideInsuranceInstallment">
            <OfferTableRow :no-separator="true">
              <OfferTableField class="pl-2 sm:pl-6"  type="label">ubezpieczenie OC/AC/NNW</OfferTableField>
              <OfferTableField></OfferTableField>
              <OfferTableField>składka i rata wyliczana indywidualnie</OfferTableField>
            </OfferTableRow>
          </template>


          <template v-if="!liczakOffer?.offer.hideInsurance && liczakOffer?.offer?.gapEnabled">
          <OfferTableRow v-for="(value, amount, idx) in liczakOffer?.offer.insurance.secondOption.installment.value" no-separator>
            <OfferTableField class="pl-2 sm:pl-6"  type="label">{{ liczakOffer?.offer.insurance.secondOption.installment.label }}</OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField>{{ amount }}</OfferTableField>
            <OfferTableField>{{ value.net }}</OfferTableField>
            <OfferTableField>{{ value.gross }}</OfferTableField>
          </OfferTableRow>
          </template>
          <template v-if="liczakOffer?.offer.client !== 'consumer' && liczakOffer?.offer.showDrivePlus">
            <OfferHeader :class="{'mt-6': !liczakOffer?.offer.hideInsurance}">Pakiet DRIVE+</OfferHeader>
            <OfferTableRow no-separator>
              <OfferTableField type="label" />
              <OfferTableField></OfferTableField>
              <OfferTableField>ilość rat</OfferTableField>
              <OfferTableField>netto</OfferTableField>
              <OfferTableField>brutto</OfferTableField>
            </OfferTableRow>
            <OfferTableRow no-separator v-if="liczakOffer?.offer.drivePlus.amount">
              <OfferTableField class="pl-2 sm:pl-6"  type="label">doliczany do miesięcznej raty leasingowej</OfferTableField>
              <OfferTableField></OfferTableField>
              <OfferTableField>{{ liczakOffer?.offer.drivePlus.amount }}</OfferTableField>
              <OfferTableField>{{ liczakOffer?.offer.drivePlus.net }}</OfferTableField>
              <OfferTableField>{{ liczakOffer?.offer.drivePlus.gross }}</OfferTableField>
            </OfferTableRow>
          </template>

        </Panel>
        <Panel class="p-6 border-2 border-azure text-azure" v-if="liczakOffer?.offer.installmentSummary.amount">
          <OfferTableRow no-separator>
            <OfferTableField class="text-sm pl-2 sm:pl-6 lg:text-xl" type="label">Twoja rata za samochód</OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.installmentSummary.amount }}</OfferTableField>
            <OfferTableField :class="{'text-sm lg:text-xl': liczakOffer?.offer.client === 'entrepreneur'}">{{ liczakOffer?.offer.installmentSummary.net }}</OfferTableField>
            <OfferTableField :class="{'text-sm lg:text-xl': liczakOffer?.offer.client === 'consumer'}">{{ liczakOffer?.offer.installmentSummary.gross }}</OfferTableField>
          </OfferTableRow>
        </Panel>

        <Panel class="p-6" v-if="isAdditionalFeesVisible">
          <OfferHeader>Opłaty dodatkowe</OfferHeader>
          <OfferTableRow no-separator>
            <OfferTableField type="label" />
            <OfferTableField></OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField>netto</OfferTableField>
            <OfferTableField>brutto</OfferTableField>
          </OfferTableRow>
          <OfferTableRow>
            <OfferTableField type="label"
            :class="{'!h-full' :!scrolledFeesDescription.registration }"
            class="h-[100px] sm:h-[70px] xl:h-[50px] print:!h-[50px] pb-3 font-light text-xs pl-2 sm:pl-6">
              <div class="flex cursor-pointer w-[220px]" @click="scrolledFeesDescription.registration = !scrolledFeesDescription.registration">
                rejestracja samochodu
                <IconChevronDown class="ml-1 print:hidden" color-fill="black" :class="{'rotate-180': scrolledFeesDescription.registration }"/>
              </div>

              <p v-show="scrolledFeesDescription.registration"class="print:!block font-light text-xs absolute max-w-[70%] text-gray-500">
                Samochód zarejestrujemy dla Ciebie szybko i sprawnie w jednym z 26 miast. Ty wybierasz w którym!
              </p>
            </OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.additionalFees.costReimbursement.net }}</OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.additionalFees.costReimbursement.gross }}</OfferTableField>
          </OfferTableRow>
          <OfferTableRow :no-separator="!liczakOffer?.offer.additionalFees.additionalInsuranceCost">
            <OfferTableField type="label"
              :class="{'!h-full' :!scrolledFeesDescription.administrationFee }"
              class="h-[100px] sm:h-[70px] xl:h-[50px] print:!h-[50px] pb-3 font-light text-xs pl-2 sm:pl-6">
              <div  class="flex cursor-pointer w-[220px]" @click="scrolledFeesDescription.administrationFee = !scrolledFeesDescription.administrationFee">
                roczna opłata administracyjna
                <IconChevronDown class="ml-1 print:hidden" color-fill="black" :class="{'rotate-180': scrolledFeesDescription.administrationFee }"/>
              </div>
                  <p v-show="scrolledFeesDescription.administrationFee"  class="print:!block font-light text-xs absolute max-w-[70%] text-gray-500">
                    W ramach rocznej opłaty administracyjnej obsługujemy m.in. cesję umowy, zmiany w umowie (skrócenie, nadpłata, zmiana terminu płatności) i mandaty. Brak tabeli opłat i prowizji.
                  </p>
            </OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.additionalFees.annualFee.net }}</OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.additionalFees.annualFee.gross }}</OfferTableField>
          </OfferTableRow>
          <OfferTableRow v-if="liczakOffer?.offer.additionalFees.additionalInsuranceCost" no-separator>
            <OfferTableField type="label">opłata za ubezpieczenie OC/AC/NNW poza ofertą VEHIS</OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.additionalFees.additionalInsuranceCost.net }}</OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.additionalFees.additionalInsuranceCost.gross }}</OfferTableField>
          </OfferTableRow>
        </Panel>
        <Panel class="p-6">
          <OfferHeader>Twój samochód</OfferHeader>
          <div>
            <OfferSpecs isLiczak :group="liczakOffer?.car?.brandName + ' ' + liczakOffer?.car?.versionName">
              <OfferSpec label="Stan samochodu">
                <span class="font-normal">{{ liczakOffer?.car.state }}</span>
              </OfferSpec>
              <OfferSpec label="Rok produkcji">
                <span class="font-normal">{{ liczakOffer?.car.productionYear }}</span>
              </OfferSpec>
              <OfferSpec label="Zarejestrowany">
                <span class="font-normal">{{ liczakOffer?.car.registered ? 'Tak' : 'Nie' }}</span>
              </OfferSpec>
              <OfferSpec label="Przebieg" v-if="liczakOffer?.car.mileage">
                <span class="font-normal">{{ liczakOffer?.car.mileage }} km</span>
              </OfferSpec>
            </OfferSpecs>
          </div>

          <Accordion :expanded="moreEquipment || pdfMode">
            <div v-if="hasAdditionalEquipment">
              <OfferHeader>Wyposażenie dodatkowe</OfferHeader>
              <div class=" font-extralight print:columns-2 md:columns-2 py-4 mx-8">
                  <div v-for="additionalEquipItem in additionalEquipments" :key="additionalEquipItem">
                    {{ additionalEquipItem }}
                  </div>
              </div>
            </div>
            <OfferHeader v-if="liczakOffer?.car.equipment.length">Wyposażenie
              <span v-if="hasAdditionalEquipment">podstawowe</span></OfferHeader>
              <div v-if="liczakOffer?.car.equipment.length">
                <OfferEquipment v-for="(eqs, group) in equipment" :group="group" :equipment="eqs" />
              </div>

            <a id="specs"></a>
            <OfferHeader>Dane techniczne</OfferHeader>
            <div>
              <OfferSpecs group="Nadwozie">
                <OfferSpec label="Typ nadwozia">{{ liczakOffer?.car.bodyType }}</OfferSpec>
                <OfferSpec label="Liczba drzwi">{{ liczakOffer?.car.doors }}</OfferSpec>
                <OfferSpec label="Liczba siedzeń">{{ liczakOffer?.car.seats }}</OfferSpec>
              </OfferSpecs>

              <OfferSpecs group="Pojemność">
                <OfferSpec label="Standardowa pojemność bagażnika (VDA)">{{ liczakOffer?.car.trunkCapacityMin }}&nbsp;l
                </OfferSpec>
              </OfferSpecs>
            </div>
          </Accordion>

          <div class="flex justify-end mr-6" v-if="!pdfMode">
            <ButtonToggle class="font-light text-azure !fill-azure" v-model:toggled="moreEquipment" with-chevron>
              Pokaż pełne wyposażenie
              <template #toggled>Ukryj pełne wyposażenie</template>
            </ButtonToggle>
          </div>
        </Panel>
        <div class="page-break"></div>
        <div>
          <SubContentHeader>Kompleksowa ochrona Twojego samochodu
          </SubContentHeader>
          <div class="grid grid-cols-1 2xl:grid-cols-2 gap-6 mb-16 pt-6 pb-6 px-6 shadow-box-light print:shadow-none print:border print:border-gray-300 rounded-lg z-[1] relative">
            <BlockPackagesCard
              title="Pakiet Drive+"
              :items="drivePlusItems"
              :noAssistance="!(liczakOffer?.car.type ===  'passenger')"
              :imgClass="'print:max-w-[24rem] lg:max-w-[28rem] xl:max-w-64'"
            />
            <BlockPackagesCard
              title="Ubezpieczenie pełnej wartości samochodu"
              :items="insuranceItems"
              :noAssistance="false"
            />

          </div>
        </div>
        <div class="print:break-inside-avoid">
          <SubContentHeader>Korzyści oferty
            <span v-if="!isCarkulacja">VEHIS</span>
          </SubContentHeader>
          <StaticGains />
        </div>
        <template v-if="!isCarkulacja">
              <p class="text-base font-extralight !mb-6">
                  Niniejszy materiał nie stanowi oferty w rozumieniu art. 66 K.C. i ma charakter wyłącznie informacyjny. Ostateczne warunki umowy zależą od oceny zdolności finansowanej oraz finansowanego przedmiotu i zostaną wskazane w odpowiedniej umowie, po złożeniu wniosku.
              </p>
              <p class="text-base font-extralight !mt-0">
                Kalkulacja przygotowana przez: VEHIS Finanse sp. z o.o., 40-265 Katowice, al. Murckowska 14c, KRS 0000791146, NIP: 5272896510, kapitał zakładowy 385 000
              </p>
          </template>
        <div>
          <SeoLiczakOffer v-if="!(domain.includes('carkulacja'))" />
          <SeoCarculacjaFooter v-else-if="domain.includes('carkulacja')" />
        </div>
      </LayoutMainColumn>
      <LayoutSideColumn>

        <CarOfferFloatingBoxLiczak class="hidden lg:block" :car="liczakOffer?.car" :offer="liczakOffer?.offer"
                                   :imageOnScroll="300" :no-button="pdfMode" />
      </LayoutSideColumn>
    </LayoutColumns>
    <AppFooterBarebone v-if="!(domain.includes('carkulacja'))" />
  </Container>
  <Blend />
</template>
