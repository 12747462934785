<script setup>
import { imaginChangeHost } from "~/utils/imagin";

const props = defineProps({
  car: {
    type: Object,
    default: () => ({})
  },
  placeholder: Boolean,
  chooseView: Boolean,
  offerView: Boolean
})

const alt = computed(() => props.car ? ("Leasing " + (props.car.brandName || '') + " " + (props.car.modelName || '')) : "");

const carImage = computed(() => {
  if (props.car) {
    const carImage = props.car.image || (props.car.images && props.car.images[0]);
    return imaginChangeHost(carImage);
  }
  return undefined;
});


const isImaginCar = computed(() => {
  return carImage.value?.includes('imagin');
});

const isUsedOnlyCars = computed(() => {
  const image = carImage.value;
  if (isImaginCar.value) return false;
  if (image && (image.includes("imagin.studio") || image.includes("images.vehistools.pl"))) return false;
  return !props.car?.count && props.car?.usedCount
    || (props.car?.state && !props.car.state?.pop && props.car.state !== 'Nowy') ;
});

const route = useRoute();
const isDigitalOrder = computed(() => {
  return route.name === 'transaction-form';
});

const isLiczakOffer = computed(() => {
  return route.name === 'external-offer';
});
</script>

<template>
  <div class="relative h-[165px] select-none pointer-events-none overflow-hidden"
       :class="{'bg-gradient-to-t from-30% from-white via-gray-200 via-50% to-white to-100% ': !placeholder && !isUsedOnlyCars, 'flex flex-col justify-center items-center': !isUsedOnlyCars}">
    <p v-if="!placeholder && car?.discount && chooseView" class="absolute left-0 h-8 w-10 flex items-center justify-center bg-dark-red text-white rounded-tl-lg rounded-br-lg text-xs font-normal top-0">
      -{{ car.discount }}%
    </p>
    <NuxtImg v-if="carImage" :src="carImage"
             class="z-10"
             :class="{'grayscale brightness-0 opacity-5':placeholder, 'mt-4 h-[140px]': !isUsedOnlyCars, 'object-cover w-full h-full': isUsedOnlyCars }"
             :alt="alt" loading="lazy" />
    <div v-if="!isUsedOnlyCars"
         class="absolute left-0 bottom-0 right-0 h-[80px] z-0"
         :class="{'bg-gradient-to-t from-gray-100 via-gray-200 to-gray-400': !placeholder}"
    ></div>

    <CarLabels :car="car" />


    <p v-if="!isLiczakOffer && !chooseView && !offerView && !isDigitalOrder && (car?.count || car?.usedCount)" class="absolute top-0 left-0 py-2 px-4 z-10 font-extralight text-xs rounded-br" :class="{'bg-white':isUsedOnlyCars}">
      <span>Nowe ({{ car?.count ? car.count : 0 }}) | </span>
      <span>Używane ({{ car?.usedCount ? car.usedCount : 0 }})</span>
    </p>
  </div>
</template>
