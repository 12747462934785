<template>
    <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.1069 60.144C13.6376 60.144 0.240234 46.7466 0.240234 30.2773C0.240234 13.808 13.6376 0.410645 30.1069 0.410645C46.5762 0.410645 59.9736 13.808 59.9736 30.2773C59.9736 46.7466 46.5762 60.144 30.1069 60.144ZM30.1069 1.47731C14.2136 1.47731 1.3069 14.4053 1.3069 30.2773C1.3069 46.1493 14.2136 59.0773 30.1069 59.0773C46.0002 59.0773 58.9069 46.1707 58.9069 30.2773C58.9069 14.384 45.9789 1.47731 30.1069 1.47731Z" fill="#3B61AB"/>
    <path d="M30.1076 57.0082C44.8706 57.0082 56.8383 45.0405 56.8383 30.2775C56.8383 15.5146 44.8706 3.54688 30.1076 3.54688C15.3447 3.54688 3.37695 15.5146 3.37695 30.2775C3.37695 45.0405 15.3447 57.0082 30.1076 57.0082Z" fill="url(#paint0_linear_8778_270031)"/>
    <path d="M12.8262 27.3758L22.5115 18.8851C23.0448 18.4371 23.8342 18.4371 24.3248 18.9704C24.8155 19.4611 24.8582 20.2504 24.4102 20.7838L15.4715 30.0211" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.3242 23.4077V28.7197C24.3242 29.4664 24.9002 30.0424 25.6469 30.0424C26.3936 30.0424 26.9696 29.4664 26.9696 28.7197V28.2717" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.6289 27.8237L15.4929 32.2397" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.8937 41.989C23.4843 41.989 24.7737 40.6996 24.7737 39.109C24.7737 37.5184 23.4843 36.229 21.8937 36.229C20.3031 36.229 19.0137 37.5184 19.0137 39.109C19.0137 40.6996 20.3031 41.989 21.8937 41.989Z" stroke="white" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
    <path d="M17.2643 38.4478H10.1816" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26.5436 38.4477H26.9916V35.3544L23.4502 32.2397H10.6289" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M35.6543 25.0078C35.9103 25.1785 36.1876 25.2638 36.4863 25.3278L42.5023 25.9891C43.5263 26.1171 44.4863 26.5225 45.297 27.2265L47.5156 29.1251" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M45.5098 27.333L48.1124 29.5517" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M28.7414 26.9917L28.4214 26.7357C27.5894 26.0317 27.44 24.7091 28.208 23.8557L28.464 23.5357C28.8267 23.1304 29.2534 22.7891 29.872 22.7037C30.896 22.4477 31.9414 22.7037 32.88 23.2797L35.6107 24.9651" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M35.6953 32.8584L41.1353 37.4664" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M35.6543 24.9648L45.0196 32.8582" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M34.139 31.5357C33.1577 32.6451 31.4937 32.7731 30.3844 31.8557C29.275 30.9171 29.1044 29.2531 30.0644 28.1011" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M34.1391 31.5357C35.1205 30.4263 34.9711 28.741 33.8191 27.781C32.6671 26.7997 31.0245 26.949 30.0645 28.101" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M43.8672 31.8982L46.8325 28.4849" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <defs>
    <linearGradient id="paint0_linear_8778_270031" x1="3.37695" y1="30.2775" x2="56.817" y2="30.2775" gradientUnits="userSpaceOnUse">
    <stop stop-color="#65B1FF"/>
    <stop offset="1"/>
    </linearGradient>
    </defs>
    </svg>
</template>