<script setup>
const backend = useBackend();
const route = useRoute();
const router = useRouter();

definePageMeta({
  layout: 'moto-layout',
});

const lastPathSegment = ref('');
const lastPathSegmentCapitalized = ref('');

const itemsPerPage = 18;
const currentPage = computed(() => parseInt(route.query.page) || 1);
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

lastPathSegment.value = route.path.split('/').pop();
lastPathSegmentCapitalized.value = capitalizeFirstLetter(lastPathSegment.value);

const pageData = reactive({
  categories: [],
  articles: { items: [] },
});

const totalItems = ref(0);
const { data: articlesData, pending: articlesPending, error: articlesError } = await useAsyncData(
  `articles-${lastPathSegment.value}`,
  () => {
    const backendPage = currentPage.value - 1; // Backend indeksuje strony od 0
    return backend.getMotoCategoryPageData(lastPathSegment.value, backendPage);
  },
  {
    watch: [currentPage],
  }
);
const { data: collectionsData, pending: collectionsPending, error: collectionsError } = await useAsyncData(
  `collections`,
  () => backend.getCollections()
);

watch(
  () => articlesData.value,
  (newVal) => {
    if (newVal && newVal.data) {
      pageData.articles.items = newVal.data.articles.items || [];
      totalItems.value = newVal.data.articles.totalCount || 0;
    }
  },
  { immediate: true }
);

const collections = ref([]);
watch(
  () => collectionsData.value,
  (newVal) => {
    if (newVal && newVal.data) {
      collections.value = newVal.data || [];
    }
  },
  { immediate: true }
);
const loaded = computed(() => !articlesPending.value && articlesData.value);

watchEffect(() => {
  const defaultTitle = 'Moto Vehis - najlepszy portal motoryzacyjny w Polsce';
  const defaultDescription = 'Śledź na bieżąco wydarzenia ze świata motoryzacji w Polsce i na świecie. Premiery, testy samochodów, trendy w motoryzacji. Tylko u nas! Sprawdź';
  const currentPage = parseInt(route.query.page) || 1;
  const totalPages = Math.ceil(totalItems.value / itemsPerPage);
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage = currentPage < totalPages ? currentPage + 1 : null;
  const prevUrl = prevPage 
    ? `https://vehis.pl${route.path.split('?')[0]}?page=${prevPage}` 
    : null;

  const nextUrl = nextPage 
    ? `https://vehis.pl${route.path.split('?')[0]}?page=${nextPage}` 
    : null;
  const seoTitle = currentPage > 1 
    ? `${pageData.articles.items[0]?.category?.title} - strona ${currentPage}` 
    : pageData.articles.items[0]?.category?.title ?? defaultTitle;

  const seoDescription = pageData.articles.items[0]?.category?.seo_description ?? defaultDescription;

  const canonicalUrl = `https://vehis.pl${route.fullPath}`;

  useHead({
    title: seoTitle,
    meta: [
      { name: 'description', content: seoDescription },
      { property: 'og:title', content: seoTitle },
      { property: 'og:description', content: seoDescription },
      { property: 'og:type', content: 'article' },
      { property: 'og:image', content: 'https://vehis.pl/moto/assets/img/moto-vehis-logo-new.png' },
    ],
    link: [
      { rel: 'canonical', href: canonicalUrl },
      ...(prevUrl ? [{ rel: 'prev', href: prevUrl }] : []),
      ...(nextUrl ? [{ rel: 'next', href: nextUrl }] : []),
    ],
  });
});

function goToPage(newPage) {
  const basePath = route.path.split('?')[0];

  if (newPage === 1) {
    const { page, ...restQuery } = route.query;
    router.push({ path: basePath, query: { ...restQuery } });
  } else {
    router.push({ path: basePath, query: { ...route.query, page: newPage } });
  }
}

const pageHeading = computed(() => {
  switch (lastPathSegment.value) {
    case 'wiadomosci':
      return 'Wiadomości';
    case 'zdjecia':
      return 'Zdjęcia';
    case 'na-drodze':
      return 'Na drodze';
    case 'o-leasingu':
      return 'O leasingu';
    default:
      return lastPathSegmentCapitalized.value;
  }
});
</script>

<template>
    <Container no-paddings>
      <LayoutColumns>
        <LayoutMainColumn noflex>
          <BlogBreadCrumbs
            :breadcrumbs="[
              { label: 'Strona główna', to: '/moto' },
              { label: pageHeading, to: `/moto/kategoria/${lastPathSegment}` },
            ]"
          />
          <MainContentHeader class="!text-left">
            {{ pageHeading }}
          </MainContentHeader>
          <div v-if="loaded">
            <div
              v-for="(group, groupIndex) in Math.ceil(pageData.articles.items.length / 6)"
              :key="groupIndex"
            >
              <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 mb-6">
                <template
                  v-for="(item, index) in pageData.articles.items.slice(
                    groupIndex * 6,
                    groupIndex * 6 + 6
                  )"
                  :key="`article-${index}`"
                >
                  <BlogMainCard :index="index" :item="item" />
                </template>
              </div>
              <div
                v-if="groupIndex === 0 && collections.length"
                class="w-full mb-12"
              >
                <CarCollection
                  :is-category-page="true"
                  :is-moto="true"
                  :title="'Zobacz najtańsze samochody dostępne w leasingu VEHIS'"
                  :search="collections[0]?.search"
                  :href="collections[0]?.slug"
                  limit="12"
                />
              </div>
            </div>
          </div>

          <div v-else>
            <p>Ładowanie artykułów...</p>
          </div>

          <BlogPagination
            :total-items="totalItems"
            :items-per-page="itemsPerPage"
            :current-page.sync="currentPage"
            @page-changed="goToPage"
          />
        </LayoutMainColumn>

        <LayoutSideColumn class="hidden lg:block pb-4 !bg-transparent">
          <BannerSidebar :bannerSlot="'moto-kategoria-300x600'" class="mt-6"/>
        </LayoutSideColumn>
      </LayoutColumns>
    </Container>
</template>
