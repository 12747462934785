<template>
    
<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32.1069 62.144C15.6376 62.144 2.24023 48.7466 2.24023 32.2773C2.24023 15.808 15.6376 2.41064 32.1069 2.41064C48.5762 2.41064 61.9736 15.808 61.9736 32.2773C61.9736 48.7466 48.5762 62.144 32.1069 62.144ZM32.1069 3.47731C16.2136 3.47731 3.3069 16.4053 3.3069 32.2773C3.3069 48.1493 16.2136 61.0773 32.1069 61.0773C48.0002 61.0773 60.9069 48.1707 60.9069 32.2773C60.9069 16.384 47.9789 3.47731 32.1069 3.47731Z" fill="#3B61AB"/>
<path d="M32.1076 59.0082C46.8706 59.0082 58.8383 47.0405 58.8383 32.2775C58.8383 17.5146 46.8706 5.54688 32.1076 5.54688C17.3447 5.54688 5.37695 17.5146 5.37695 32.2775C5.37695 47.0405 17.3447 59.0082 32.1076 59.0082Z" fill="url(#paint0_linear_8778_270187)"/>
<path d="M24.5332 15.3174H28.4585C29.8665 15.3174 31.2532 15.744 32.3839 16.4907L39.1465 21.0774C39.4879 21.312 39.8505 21.4614 40.2772 21.504" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M23.4883 15.3174H28.0749" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M40.3203 21.4614L44.6296 22.1228C46.1016 22.3574 47.4243 23.0188 48.2136 24.2134C48.7256 24.8748 48.9176 25.6214 48.9176 26.3468V26.8588C48.9176 28.3734 47.6803 29.6108 46.1656 29.6108H45.5896" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M23.7227 29.5469H33.3227" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M23.7227 21.3975L40.32 21.4615" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M43.2422 29.5469C43.2422 31.5735 41.6422 33.1309 39.6582 33.1309C37.6742 33.1309 36.0742 31.5309 36.0742 29.5469" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M43.2422 29.5469C43.2422 27.5202 41.6422 25.9629 39.6582 25.9629C37.6742 25.9629 36.0742 27.5629 36.0742 29.5469" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M25.75 15.3174V21.3974" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M28.1172 44.3309H39.6585C40.5119 44.3309 41.3225 43.9896 41.9199 43.4349L49.1519 37.3762C49.9625 36.6296 49.6639 35.2429 48.6399 34.8162C47.7439 34.4749 46.8479 34.7309 45.5679 35.5629L39.6159 39.4882" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.1873 39.9789H36.182C36.9926 39.8935 37.5473 39.2322 37.59 38.4215C37.59 37.4829 36.9286 36.7149 35.99 36.6722C32.7686 36.6722 30.5073 35.4349 28.054 34.8375C23.702 34.1335 21.5686 36.5869 21.0566 37.2909" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.7868 40.7683L17.9201 38.6349C18.3468 38.2083 19.0935 38.2083 19.5201 38.6349L25.2375 44.3523C25.6641 44.7789 25.6641 45.5256 25.2375 45.9523L23.1041 48.0856C22.6775 48.5123 21.9308 48.5123 21.5041 48.0856L15.7868 42.3683C15.3601 41.9416 15.3601 41.1949 15.7868 40.7683Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_8778_270187" x1="5.37695" y1="32.2775" x2="58.817" y2="32.2775" gradientUnits="userSpaceOnUse">
<stop stop-color="#65B1FF"/>
<stop offset="1"/>
</linearGradient>
</defs>
</svg>

</template>