<script setup>
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      agreement1: false,
      agreement2: false,
      agreement3: false,
      agreement4: false,
      agreement5: false,
      agreement6: false
    })
  },
  agreementAdded: {
    type: Object,
    default: () => false
  },
  errors: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['update:modelValue']);

const innerValue = ref({
  agreement1: props.modelValue.agreement1
});
const allChecked = computed(() => {
  return Object.values(innerValue.value).every(v => v === true);
});
const isCheckedAll = (value) => {
  for (let agreement in innerValue.value) {
    innerValue.value[agreement] = value
  }
}

const isAllValidMessage = computed(() => {
  let all = false;
  for (let agreement in props.errors) {
    all = all || props.errors[agreement]
  }
  return all ? 'Wszystkie zgody wymagane' : false;
})

watch(() => props.modelValue, (newValue) => {
  Object.assign(innerValue.value, newValue);
}, { immediate: true });

// Emituj zmiany do rodzica
watch(innerValue, (newVal) => {
  emit('update:modelValue', newVal);
}, { deep: true });
watch(() => props.agreementAdded, (newVal) => {
  if (newVal === true) {
    isCheckedAll(true);
  }
});

const expandAgreements = ref(false);

</script>

<template>
  <div class="grid grid-cols-2 mt-0 gap-2 pt-4">
    <div class="col-span-2 font-light text-xl ">
      Oświadczenia i pełnomocnictwa
    </div>
    <div class="col-span-2 font-light text-xs ">
      Poniżej znajdziesz oświadczenia i pełnomocnictwa niezbędne do realizacji zamówienia. Zapoznaj się z ich treścią, zanim je zaakceptujesz.
    </div>
    <div class="flex items-center col-span-2">
      <div>
        <template>
            <InputCheckBox class="!text-black inline"
                flex
                :looksLikeDisabled="agreementAdded"
                 v-model:checked="innerValue.agreement1"
                :checked="allChecked"
                @update:checked="isCheckedAll"
                :error="isAllValidMessage"
                :info="isAllValidMessage"
                >
                <span class="!text-black font-light inline">Zgoda na sprawdzenie w bazach (Biurze Informacji Kredytowej oraz Krajowym Rejestrze Długów)<span class="text-rose-600 inline-block ml-1 mr-2">* </span> –</span>
            </InputCheckBox>
            <ButtonToggle @toggle="(e) => expandAgreements = e">
            <template v-slot:default>
                <span class="text-xs font-extralight flex ml-4"><span> Zobacz pełną treść</span> <IconChevronDown class="!w-3 !h-3 fill-black" /></span>
            </template>
            <template v-slot:toggled>
                <span class="text-xs font-extralight flex ml-4"><span>zwiń</span> <IconChevronDown class="!w-3 !h-3 rotate-180 fill-black" /></span>
            </template>
            </ButtonToggle>
        </template>
        <Accordion
        :expanded="expandAgreements"
            class="pl-4"
        >
          <div class="col-span-2 text-xs pb-4 !text-black font-extralight mt-2">
            Wyrażam zgodę na:
          </div>
          <ol class="text-xs pb-4 !text-black font-extralight list-decimal pl-8">
            <li class="mb-2">
                Przekazanie przez Vehis Finanse sp. z o.o. do Biura Informacji Kredytowej S.A.:
                <ol class="flex flex-col gap-2 list-disc pl-4 pt-2">
                  <li>moich danych osobowych (zapytanie) w celu pozyskania informacji, w tym  stanowiących tajemnicę bankową dotyczących mnie jako przedsiębiorcy, a także dotyczących jako osoby fizycznej – konsumenta, przetwarzanych w BIK dla oceny zdolności kredytowej i analizy ryzyka kredytowego oraz przetwarzanie w tym celu przez BIK danych osobowych przekazanych przez VEHIS w zapytaniu, przez okres nie dłuższy niż 2 lata, w tym ich udostępnianie bankom, instytucjom ustawowo upoważnionym do udzielania kredytów, instytucjom kredytowym oraz innym podmiotom na podstawie udzielonej przez mnie zgody. </li>
                  <li>moich danych osobowych, oraz przetwarzanie tych danych przez BIK do dnia odwołania zgody, w celu oceny zdolności kredytowej i analizy ryzyka kredytowego, w tym ich udostępnianie bankom, instytucjom ustawowo upoważnionym do udzielania kredytów, instytucjom kredytowym oraz innym podmiotom na podstawie udzielonej im przeze mnie zgody.</li>
                </ol>
            </li>
            <li>Wystąpienie przez Vehis Finanse Sp. z o.o. do biur informacji gospodarczej (np.  Krajowego Rejestru Długów S.A.), w tym za pośrednictwem BIK,  o ujawnienie informacji gospodarczych dotyczących moich zobowiązań, w tym zaciągniętych przeze mnie jako konsumenta. </li>
          </ol>
          <p class="!text-black font-extralight text-xs">Powyższe zgody mogą zostać odwołane w każdym czasie. Odwołanie zgód nie wpływa na zgodność z prawem przetwarzania danych przed wycofaniem zgody.</p>
        </Accordion>
        <div class="col-span-2 font-light text-xs mt-4">
        <span class="text-rose-600">*</span> Wymagane
    </div>
      </div>
    </div>
  </div>
</template>
