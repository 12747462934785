export const prepareRequestQuery = (parameters) => {
  let params = sanitizeRequestQueryParams(parameters);
  let query = new URLSearchParams(params);
  return query.toString()
}

export const sanitizeRequestQueryParams = (parameters) => {
  let params = {...parameters}
  delete params.filter;
  delete params.mode;

  if (params.body) {
    params.bodyType = params.body;
    delete params.body;
  }

  if (params.brands) {
    params.brand = params.brands;
    delete params.brands;
  }

  return params;
}

export const sortData = (obj, desc = false) => {
  if (Array.isArray(obj)) {
    return obj.sort();
  }
  const sorted = Object.entries(obj).sort(([, a], [, b]) => {
    return a > b ? 1 : -1
  });

  const result = new Map();
  sorted.forEach(item => {
    const [key, value] = item;
    result.set(key, value)
  })
  return result
}

export async function getServerDate() {
  const serverDate = useState('serverDate',() => null);
  if(serverDate.value !== null) {
    const syncDate = new Date(Date.now() + serverDate.value);
    return syncDate;
  }
  const response = await $fetch("/srv/date", {
    ignoreResponseError: true,
    parseResponse: JSON.parse
  });
  if (!response.date) return Date.now();

  serverDate.value = parseInt(response.date) - Date.now();
  return new Date(response.date);
}

export function fisherYates(array) {
  var count = array.length,
    randomnumber,
    temp;
  while (count) {
    randomnumber = Math.random() * count-- | 0;
    temp = array[count];
    array[count] = array[randomnumber];
    array[randomnumber] = temp
  }
}
