<template>
  <svg
    class="cursor-pointer"
    width="11"
    height="20"
    viewBox="0 0 11 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <path
      d="M9.75 18.75L1 10L9.75 1.25"
      :stroke="hover ? hoverColor : strokeColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup>
import { ref } from 'vue'

// Stan hover
const hover = ref(false)

const props = defineProps({
  strokeColor: {
    type: String,
    default: '#3F3F46' // Domyślny kolor obrysu
  },
  hoverColor: {
    type: String,
    default: '#0A66A8' // Kolor obrysu na hover
  }
})
</script>

<style scoped>
/* Jeśli potrzeba dodatkowych stylów, można je dodać tutaj */
</style>
