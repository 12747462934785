<script setup>
const props = defineProps({
  title: {
    type: String,
    default: () => 'Jak skorzystać z oferty'
  },
  description: {
    type: String,
    default: () => 'Proces nabycia samochodu w VEHIS jest prosty i przyjazny'
  },
  hideDescription: {
    type: Boolean,
    default: () => false
  },
  steps: {
    type: Array,
    default: () => 
    [
      'Wybierz samochód z ponad <b>3000</b> dostępnych ofert.',
      '<b>Wspólnie skalkulujemy</b> ofertę<br/>finansowania i ubezpieczenia.',
      'Finansowanie przyznamy<br/><b>w 15 minut</b> od złożenia wniosku.',
      'Samochód odbierzesz nawet<br/><b>w 24h</b> od podpisania umowy.'
    ]
  },
  textCenter: {
    type: Boolean,
    default: () => true
  },
  textLeft: {
    type: Boolean,
    default: () => false
  },
  customHeadingClass: {
    type: String,
    default: () => ''
  },
  negMargin: {
    type: Boolean,
    default: () => true
  },
  noSelected: {
    type: Boolean,
    default: () => false
  },
  hideArrows: {
    type: Boolean,
    default: () => false
  }
});

const selectedStepIndex = computed(() => props.steps.length - 1);
</script>

<template>
  <SubContentHeader :customHeadingClass="customHeadingClass" :centered="textCenter">{{ title }}</SubContentHeader>
    <slot name="headerDescription"/>
  <ContentDescription v-if="!hideDescription" :textLeft="textLeft"  glued>{{ description }}</ContentDescription>
  <ContentSteps :neg-margin="negMargin" class="-mt-20 md:mt-0">
    <ContentStep hideArrows :selected="noSelected ? !noSelected : index === selectedStepIndex"  :key="index" v-for="(step, index) in props.steps">
      <div v-html="step"></div>
    </ContentStep>
  </ContentSteps>
</template>