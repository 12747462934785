<script setup>
const props = defineProps({
  useUrlQuery: Boolean,
  cars: Array,
  availableEquipment: Map,
})

const emits = defineEmits(['change']);
const preset = defineModel('preset', {default: () => ({})});
const storedQuery = useState('storedSearchQuery', () => ({}));

const {getPower} = useParameters();
const {getDictionaries, getDictionariesKeys, revTranslate} = useDictionaries();
const {filterOptionsEventNames, analyticsLayer} = useAnalytics();

const dropDowns = ref({});
const showAll = ref(false);

const addToDropDowns = (el, name) => dropDowns.value[name] = el;
const clearForm = () => Object.keys(dropDowns.value).forEach(key => dropDowns.value[key].reset());

const options = ref({
  body: [],
  productionYear: [],
  seats: [],
  power: getPower(),
  fuel: getDictionariesKeys().fuel.toMap(true),
  gearbox: getDictionariesKeys().gearbox.toMap(true),
  drive: getDictionariesKeys().drive.toMap(true),
});

const search = ref({});
const query = ref({});

const performSearch = (filter, value, isReset) => {
  value = toRaw(value);
  query.value = {...query.value}; //toRaw({...toRaw(search.value)});

  if (!isReset) analytics(filter, value);
  if (filter.startsWith("power")) {
    compare(value, filter === 'powerMin' ? 'powerMax' : 'powerMin', false);
  }

  switch (filter) {
    case 'fuel':
    case 'gearbox':
    case 'drive':
      if(typeof value.pop !== 'undefined') {
        let values = [];
        value.forEach(v => {
          values = values.concat(getDictionaries()[filter][v])
        });
        query.value[filter] = values.unique();
      } else {
        query.value[filter] = getDictionaries()[filter][value] || []
      }
      break;
    default:
      query.value[filter] = value;
  }
  emits('change', query.value);
}

const analytics = (type, value) => {
  analyticsLayer({
    'event': filterOptionsEventNames[type],
    'filter_type': Object.values(value).join(",")
  });
}

const compare = (base, toCompare, less) => {
  if (search.value[toCompare] && search.value[toCompare][0] && base[0]) {
    base = parseInt(toRaw(base)[0]);
    const value = parseInt(search.value[toCompare][0]);

    if (less && base < value || !less && base > value) {
      search.value[toCompare] = [];
    }
  }
}
const reduceDictionaryToAvailableOptions = (dictionaryName, reduced) => {
  const translated = revTranslate(dictionaryName, reduced);
  return getDictionariesKeys()[dictionaryName].filter(i => translated.indexOf(i) !== -1);
}

const reduceOptions = (cars) => {
  options.value.body = cars.map(i => i.bodyType.toLowerCase()).unique().toMap(true, (i) => i.toLowerCase(), (i) => i.upperCaseFirst());
  options.value.seats = cars.map(c => c.seats.toString()).unique().toMap(true);
  options.value.productionYear = cars.map(i => i.productionYear.toString()).unique().toMap(true);
  options.value.drive = reduceDictionaryToAvailableOptions('drive', cars.map(i => i.driveType.toLowerCase()).unique()).toMap(true);
  options.value.fuel = reduceDictionaryToAvailableOptions('fuel', cars.map(i => i.fuelType.toLowerCase()).unique()).toMap(true);
  options.value.gearbox = reduceDictionaryToAvailableOptions('gearbox', cars.map(i => i.gearboxType.toLowerCase()).unique()).toMap(true);
}

const presetOptions = () => {
  Object.keys(storedQuery.value).forEach(key => {
    const value = storedQuery.value[key];
    if(value !== undefined) {
      search.value[key] = toRaw(value);
      if(dropDowns.value[key]) {
        let val;
        if (['fuel', 'gearbox', 'drive'].includes(key)) {
          val = revTranslate(key, toRaw(value)).unique();
        } else {
          val = toRaw(value);
        }
        dropDowns.value[key].set(val);
        performSearch(key, val);
      }
    }
  })
}

onMounted(() => {
  reduceOptions(props.cars);
  presetOptions();
})

defineExpose({
  clearForm
})

</script>

<template>
  <div class="relative">
    <div class="grid grid-cols-4 md:grid-cols-8 xl:grid-cols-16 gap-2 w-full items-end"
         :class="{'grid-cols-5':!showAll}">
      <InputDropDown :ref="(el) => addToDropDowns(el,'body')"
                     :options="options.body"
                     :preset="preset?.body"
                     @change="(v, isReset) => performSearch('body',v,isReset)" label="Nadwozie" class="col-span-2" multiple />

      <InputDropDown :ref="(el) => addToDropDowns(el,'productionYear')"
                     :options="options.productionYear"
                     @change="(v, isReset) => performSearch('productionYear',v,isReset)" label="Rok produkcji" class="col-span-2" multiple />

      <InputDropDown :ref="(el) => addToDropDowns(el,'fuel')"
                     :options="options.fuel"
                     @change="(v, isReset) => performSearch('fuel',v,isReset)" label="Paliwo" class="col-span-2" multiple />

      <InputDropDown :class="['lg:block', {'hidden':!showAll}]" :ref="(el) => addToDropDowns(el,'gearbox')"
                     :options="options.gearbox"
                     @change="(v, isReset) => performSearch('gearbox',v,isReset)" label="Skrzynia biegów" class="col-span-2" multiple />

      <InputDropDown :class="['lg:block', {'hidden':!showAll}]" :ref="(el) => addToDropDowns(el,'drive')"
                     :options="options.drive"
                     @change="(v, isReset) => performSearch('drive',v,isReset)" label="Napęd" class="col-span-2" multiple />

      <InputDropDown :class="['lg:block', {'hidden':!showAll}]" :ref="(el) => addToDropDowns(el,'seats')"
                     :options="options.seats"
                     @change="(v, isReset) => performSearch('seats',v,isReset)" label="Ilość miejsc" class="col-span-2" multiple />

      <InputDropDown :class="['lg:block', {'hidden':!showAll}]" :ref="(el) => addToDropDowns(el,'powerMin')"
                     :options="options.power"
                     @change="(v, isReset) => performSearch('powerMin',v,isReset)" label="Moc od" class="col-span-2" />

      <InputDropDown :class="['lg:block', {'hidden':!showAll}]" :ref="(el) => addToDropDowns(el,'powerMax')"
                     :options="options.power"
                     @change="(v, isReset) => performSearch('powerMax',v,isReset)" label="Moc do" class="col-span-2" />

      <ButtonToggle class="block lg:hidden text-azure text-sm" @toggle="(v) => showAll = v">
        <template v-slot:default>+6 filtrów</template>
        <template v-slot:toggled>zwiń</template>
      </ButtonToggle>


    </div>
  </div>
</template>
