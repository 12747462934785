<script setup>

const {onMouseUp, onMouseMove} = useMouseEvents();

const props = defineProps({
  fields: Boolean,
  compact: Boolean,
  min: Number,
  max: Number,
  step: {
    type: Number,
    default: 1
  }
})

const emits = defineEmits(['changed']);

const values = defineModel('values', {default: () => ({left: 0, right: 0})})
const handlePosition = ref({left: '0px', right: '0px'});

const positionBoundaries = computed(() => {
  return {
    left: {
      min: 0,
      max: parseInt(handlePosition.value.right) - handleSize
    },
    right: {
      min: parseInt(handlePosition.value.left) + handleSize,
      max: trackWidth.value - handleSize
    }
  }
})

const handleSize = 40;
let currentHandleElement = null;

const dragging = ref(false);
const trackX = ref(0);
const trackWidth = ref(0);

const maxValue = ref(props.max);
const minValue = ref(props.min);
const stepValue = ref(props.step);

const ratio = ref(1);

let handleDragOffsetX = 0;

const drag = (event) => {
  if (!dragging.value) return;
  const pageX = event.pageX || event.touches[0].pageX;
  const handleName = currentHandleElement.dataset.name;
  let left = pageX - trackX.value - handleDragOffsetX;

  const minValue = positionBoundaries.value[handleName].min;
  const maxValue = positionBoundaries.value[handleName].max;

  left = Math.min(Math.max(left, minValue), maxValue);

  setHandlePosition(left, handleName)
  calculateValue(left, handleName)
}

const setHandlePosition = (position, type) => {
  handlePosition.value[type] = position + "px";
}

const calculateValue = (position, type) => {
  const offset = type === 'right' ? handleSize : 0;
  const calculatedValue = Math.round(Math.round(((position - offset) / ratio.value) / stepValue.value) * stepValue.value);

  if (type === 'left') {
    values.value.left = calculatedValue + minValue.value;
  } else {
    values.value.right = calculatedValue + minValue.value;
  }
}

const startDrag = (event) => {
  dragging.value = true;
  currentHandleElement = event.target;
  const {x} = currentHandleElement.getBoundingClientRect();
  handleDragOffsetX = (event.pageX || event.touches[0].pageX) - x;
}

const calculateRatio = () => {
  if (trackWidth.value === 0) return 0;
  ratio.value = (trackWidth.value - 2 * handleSize) / (maxValue.value - minValue.value);
}

const updateTrack = () => {
  calculateRatio();
  setHandlePosition((values.value.left - minValue.value) * ratio.value, 'left');
  setHandlePosition((values.value.right - minValue.value) * ratio.value + handleSize, 'right');
}
const setTrack = (width, x) => {
  trackWidth.value = width;
  trackX.value = x;
  updateTrack();
}

const updateValueFromInput = ($event) => {
  const newValue = $event.target.value;
  const name = $event.target.name;

  if (name === 'right') {
    values.value.right = Math.max(Math.min(newValue, maxValue.value), values.value.left);
  } else {
    values.value.left = Math.max(Math.min(newValue, values.value.right), minValue.value);
  }

  updateTrack()
}

onMouseMove((event) => drag(event))
onMouseUp(() => {
  dragging.value = false;
  emits('changed', values.value)
})

onMounted(() => {
  if (parseInt(values.value.left) > parseInt(values.value.right)) {
    const tmp = parseInt(values.value.right);
    values.value.right = parseInt(values.value.left);
    values.value.left = tmp;
  }

  if (values.value.right === 0) {
    values.value.right = maxValue.value;
  }

  updateTrack();
})

watch(() => values.value,
  (newValue) => {
    const tmp = {...newValue};

    if (tmp.right < tmp.left) {
      const t = {...tmp};
      tmp.left = t.right;
      tmp.right = t.left;
    }

    if (tmp.left === null) tmp.left = minValue.value;
    if (tmp.right === null) tmp.right = maxValue.value;

    if (tmp.left < minValue.value) tmp.left = minValue.value;
    if (tmp.right < minValue.value) tmp.right = minValue.value;

    if (tmp.left > maxValue.value) tmp.left = maxValue.value;
    if (tmp.right > maxValue.value) tmp.right = maxValue.value;

    values.value.left = tmp.left;
    values.value.right = tmp.right;

    updateTrack()
  },
  {deep: true}
);

watch(() => props.min,
  (newValue) => {
    minValue.value = newValue
    updateTrack();
  },
  {deep: true}
);

watch(() => props.max,
  (newValue) => {
    maxValue.value = newValue
    updateTrack();
  },
  {deep: true}
);

</script>

<template>
  <div class="relative">
    <div class="flex mt-4" :class="[{'flex-wrap justify-between':compact, 'justify-center items-center gap-4':!compact}]">
      <div class="order-1">
        <InputBasic type="number"
                    name="left"
                    v-model="values.left"
                    @change="updateTrack"
                    price-format
        />

      </div>
      <div :class="[{'order-3':!compact, 'order-2':compact}]">
        <InputBasic type="number"
                    v-model="values.right"
                    name="right"
                    @change="updateTrack"
                    price-format
        />

      </div>
      <div class="relative h-10 my-4 z-10"
           :class="[{'w-full order-3': compact, 'grow order-2': !compact}]">
        <InputRangeTrack ref="refTrack" @resize="(tw,x) => setTrack(tw,x)"
                         v-model:left-value="handlePosition.left"
                         v-model:right-value="handlePosition.right"
        />
        <InputRangeHandle data-name="left"
                          @mousedown.prevent="startDrag" @touchstart.passive="startDrag"
                          :style="{left: handlePosition.left}"
        />
        <InputRangeHandle data-name="right"
                          @mousedown.prevent="startDrag" @touchstart.passive="startDrag"
                          :style="{left: handlePosition.right}"
        />
      </div>
    </div>
  </div>
</template>
