<script setup>

const props = defineProps({
  states: Object,
  value: [String,Number]
})
const emits = defineEmits(['change', 'update:value']);
const stateList = ref(props.states);
const waitForChange = useState('waitForChange')
const defaultValue = defineModel('value');
const gridColumnStyleValue = computed(() => 'repeat('+Object.keys(props.states).length+', minmax(0, 1fr))');

const highlightPosition = computed(() => {
  const values = Object.keys(stateList.value);
  const position =  values.indexOf(defaultValue.value.toString());
  return position * (100 / values.length) + "%" ;
})

const highlightWidth = computed(() => {
  const values = Object.keys(stateList.value);
  return (100 / values.length) + "%";
})

const onChange = (value) => {
  if(!waitForChange.value){
  emits('change', value);
  emits('update:value', value); 
  }
}

</script>

<template>
  <div class="relative rounded h-10 bg-gray-100 select-none">
    <div class="z-10 absolute inset-0 font-extralight items-center p-1 h-10" :style="{'grid-template-columns': gridColumnStyleValue}">
      <div class="bg-white rounded h-full shadow-box transition-all"
           :style="{'margin-left': highlightPosition, 'width': highlightWidth}">
      </div>
    </div>
    <div class="z-20 absolute inset-0 grid font-extralight items-center p-1 h-10" :style="{'grid-template-columns': gridColumnStyleValue}">
      <div v-for="(label, value) in stateList"
           class="text-center grow cursor-pointer"
           :class="{'text-azure font-normal': value == defaultValue, 'animate-pulse pointer-events-none': waitForChange}"
           @click="defaultValue = value.toString(); onChange(value);"
      >
        {{ label }}
      </div>
    </div>
  </div>
</template>
