import { getServerDate } from "~/utils/misc";

export const useParameters = () => {

  const route = useRoute();
  const {getDictionaries} = useDictionaries();
  const storedQuery = useState('storedSearchQuery', () => ({}));
  const prices = useState('storedPrices', () => ({
    min: 40000,
    max: 800000
  }))

  const getDefaultPrices = () => {
    return {
      min: Math.round(prices.value.min * 1.23 / 1000) * 1000,
      max: Math.round(prices.value.max * 1.23 / 1000) * 1000
    };
  }

  const getDefaults = (key, useUrlQuery = false) => {
    let defaultValue;
    switch (key) {
      case "priceMin":
        defaultValue = Math.round(prices.value.min * 1.23);
        break;
      case "priceMax":
        defaultValue = Math.round(prices.value.max * 1.23);
        break;
      default:
        defaultValue = [];
        break;
    }

    if (storedQuery.value[key]) {
      defaultValue = storedQuery.value[key]
    }

    if (['fuel', 'gearbox', 'drive'].indexOf(key) !== -1) {
      const query = useUrlQuery ? route.query[key] : storedQuery.value[key];
      if (query) {
        const queryValues = query?.pop ? query : [query];
        defaultValue = [];
        queryValues.forEach(queryValue => {
          Object.keys(getDictionaries()[key]).forEach((dicKey) => {
            const dicItems = getDictionaries()[key][dicKey];
            dicItems.forEach(item => {
              if (item === queryValue) {
                defaultValue.push(dicKey);
              }
            })
          })
        })
        defaultValue = defaultValue.unique();
      }
    } else {
      defaultValue = useUrlQuery ? (route.query[key] || defaultValue) : defaultValue;
      if (!key.startsWith('price') && typeof defaultValue.pop === "undefined") {
        defaultValue = [defaultValue];
      }
    }
    return defaultValue;
  }

  const getPower = () => {
    const power = new Map();
    "50,80,120,150,200,250,300,500".split(",").forEach(i => power.set(`${i}`, `${i}`));
    return power;
  }

  const getProductionYears = async () => {
    const years = new Map();
    const curYear = parseInt((await getServerDate()).getFullYear());
    for (let i = curYear; i > curYear - 6; i--) years.set(`${i}`, `${i}`);
    return years;
  }

  const getSeats = () => {
    const seats = new Map();
    for (let i = 1; i < 10; i++) seats.set(`${i}`, `${i}`);
    return seats;
  }

  const getGearBoxes = async () => {
    const api = await useBackend().getGearBoxes();
    const gearboxes = new Map();
    api.data.forEach(d => {
      const i = d.name;
      gearboxes.set(`${i}`, `${i}`)
    })
    return gearboxes;
  }

  const getDriveTypes = async () => {
    const api = await useBackend().getDrives();
    const dr = new Map();
    api.data.forEach(d => {
      const i = d.name;
      dr.set(`${i}`, `${i}`)
    })
    return dr;
  }

  const getPromo = () => {
    return new Map();
  }

  return {
    getDefaults,
    getPower,
    getSeats,
    getPromo,
    storedQuery,
    getDefaultPrices,
    getProductionYears,
    getGearBoxes,
    getDriveTypes
  }
}
