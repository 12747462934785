<script setup>
import insuranceOfferDesktopImg from '@/assets/images/statics/insurance-offer-benefits.png';
import insuranceOfferMobileImg from '@/assets/images/statics/insurance-offer-benefits-mobile.png';
import drivePlusImg from '@/assets/images/statics/drive-plus.jpg';
import drivePlusAssistanceIcon from '@/components/Vector/DrivePlusAssistanceCircle.vue';
import drivePlusPhoneIcon from '@/components/Vector/DrivePlusPhoneCircle.vue';
import drivePlusSupportIcon from '@/components/Vector/DrivePlusSupportCircle.vue';
import drivePlusGpsIcon from '@/components/Vector/DrivePlusGpsCircle.vue';

useHead({
  title: "Ubezpieczenia VEHIS - Oferta - Vehis.pl",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
  link: [
    {rel: 'canonical', href: `https://vehis.pl${useRoute().fullPath}`}
  ]

});

const {gte, lte} = useBreakpoints();

const backend = useBackend();

const slides = useSlides();

const refControl = ref();
const refSlideNavigation = ref();

onMounted(() => slides.initSlides(refControl, refSlideNavigation))
const fields = [
  [    {
      title: 'Ubezpieczenia samochodów — obowiązek każdego kierowcy',
      content: 'Posiadanie polisy OC to nieodłączny element każdego pojazdu mechanicznego. Obowiązkowe ubezpieczenie samochodu zabezpiecza Cię przed finansowymi skutkami szkód wyrządzonych osobom trzecim. <b>OC jest niezbędne zarówno w Polsce, jak i w innych krajach europejskich,</b> w których przepisy nakazują posiadanie ważnego ubezpieczenia odpowiedzialności cywilnej.'
    },
  ],
  [
    {
      title: 'Ubezpieczenie auta — w jakich sytuacjach jest niezbędne?',
      content: 'Niezależnie od tego, czy poruszasz się po kraju, czy za granicą, umowa ubezpieczenia OC jest wymagana prawem. <b>Polisa ta chroni innych uczestników ruchu drogowego przed skutkami Twoich ewentualnych błędów.</b> Dodatkowo, ubezpieczenie AC czy GAP może pomóc w przypadku kradzieży lub zniszczenia pojazdu. AC oferuje ochronę również w przypadku uszkodzeń powstałych w wyniku klęsk żywiołowych, takich jak grad, powódź czy pożar.',
    }
  ],
  [
    {
      title: '',
      sectionHeading: 'Co obejmuje podstawowe ubezpieczenie auta?',
      content: 'Każdy pojazd zarejestrowany w Polsce musi posiadać obowiązkowe ubezpieczenie OC. Podstawowe ubezpieczenie samochodu, czyli OC (odpowiedzialność cywilna), <b>to obowiązkowa polisa, którą musi wykupić każdy właściciel pojazdu.</b> Jej głównym celem jest ochrona finansowa innych uczestników ruchu drogowego w przypadku wyrządzenia szkody przez kierowcę ubezpieczonego auta.'
    },
    {
      title: '',
      content: 'Warto pamiętać, że brak OC może skutkować karami nakładanymi przez Ubezpieczeniowy Fundusz Gwarancyjny. Ponadto w przypadku braku polisy OC odpowiedzialność finansowa za ewentualne szkody spoczywa na kierowcy, co może oznaczać bardzo wysokie koszty.'
    }
  ],
  [
    { sectionHeading: 'Ubezpieczenie OC samochodu — niezbędne i obowiązkowe',
      title: '',
      content: 'W ubezpieczeniach obowiązkowych, OC samochodu <b>pokrywa koszty naprawy lub odszkodowania w przypadku wyrządzenia szkód innym uczestnikom ruchu.</b>  OC pokrywa koszty szkód materialnych oraz osobowych, które spowoduje kierowca podczas poruszania się pojazdem. Może to dotyczyć różnych sytuacji – od drobnej stłuczki po poważny wypadek. Ubezpieczenie pokrywa m.in.:<ul><li>naprawę uszkodzonego auta poszkodowanego,</li><li>koszty leczenia osób, które ucierpiały w wypadku,</li><li>rekompensatę za utratę zdrowia lub życia,</li><li>naprawę zniszczonych elementów infrastruktury, np. płotów, latarni czy znaków drogowych.</li></ul>'
    },
    {
      title: '',
      content: 'Polisa OC <b>obejmuje także szkody wyrządzone pieszym, rowerzystom i innym</b> niechronionym uczestnikom ruchu. Jeśli kierowca potrąci pieszego lub spowoduje wypadek z udziałem rowerzysty, ubezpieczenie pokryje koszty leczenia, rehabilitacji czy odszkodowania dla poszkodowanych. Podstawowe ubezpieczenie obejmuje również sytuacje, w których szkoda powstała podczas manewrów parkingowych. Jeśli kierowca przypadkowo uszkodzi inny pojazd na parkingu, koszty naprawy pokryje jego polisa OC.'
    }
  ],
  [
    {
      title: '',
      sectionHeading: 'Ubezpieczenie AC samochodu. Czym jest i dlaczego warto je posiadać?',
      content: 'Autocasco (AC) to polisa, która zabezpiecza Twoje auto przed uszkodzeniami wynikającymi z kolizji, aktów wandalizmu, czy działania żywiołów. Zakres ochrony AC różni się w zależności od ubezpieczyciela, dlatego przed wyborem warto dokładnie przeanalizować ogólne warunki ubezpieczenia. Zwykle polisa ta obejmuje m.in. <b>szkody spowodowane przez kierowcę, uszkodzenia wynikające z działania osób trzecich,</b> a także sytuacje losowe, takie jak kradzież czy zniszczenia spowodowane warunkami atmosferycznymi. Niektóre polisy AC mogą również obejmować dodatkowe usługi, takie jak auto zastępcze, holowanie czy naprawę pojazdu na miejscu zdarzenia.'
    },
    {
      title: '',
      content: 'Ubezpieczenie AC to gwarancja ochrony finansowej w wielu nieprzewidzianych sytuacjach.<ul><li><b>Polisa pokrywa koszty naprawy auta po kolizjach,</b> kradzieży czy zniszczeniach spowodowanych przez grad, wichurę lub powódź.</li><li>Dodatkowo <b>zabezpiecza kierowcę przed stratami</b> wynikającymi z aktów wandalizmu, takich jak porysowanie lakieru czy wybicie szyby.</li><li><b>Dzięki AC można uniknąć wysokich wydatków</b> na naprawę samochodu i cieszyć się spokojem, niezależnie od okoliczności.</li>'
    },
    {
      title: '',
      content: 'Oprócz ubezpieczenia AC warto także rozważyć zakup dodatkowego ubezpieczenia NWW czy pakietu Assistance. Ubezpieczenie NNW zapewnia ochronę w przypadku obrażeń ciała kierowcy i pasażerów. Polisy Assistance z kolei mogą obejmować holowanie pojazdu, pomoc drogową, a nawet organizację transportu zastępczego (w kraju lub za granicą).'
    },
  ],
  [
  {
      title: 'Ubezpieczenie nowego samochodu — jak wygląda?',
      content: 'Kupując nowy samochód, warto zadbać o kompleksowe zabezpieczenie.  Aby zapewnić sobie pełną ochronę, oprócz ubezpieczenia OC, <b>warto rozważyć wykupienie dodatkowego Autocasco</b> (AC), które pokrywa koszty naprawy pojazdu w przypadku szkód powstałych z winy kierowcy, aktów wandalizmu czy działania żywiołów. <b>Warto również pomyśleć o ubezpieczeniu od następstw nieszczęśliwych wypadków</b> (NNW) oraz assistance, które zapewni pomoc na drodze w razie awarii lub wypadku. Ubezpieczyciele często oferują pakiety ochronne, które można dostosować do indywidualnych potrzeb, zapewniając nowemu samochodowi wszechstronną ochronę zarówno na co dzień, jak i w sytuacjach losowych.<br/><br/>Ciekawą opcją jest także <b>polisa GAP</b>, która chroni wartość fakturową auta, co jest szczególnie ważne w przypadku szkody całkowitej lub kradzieży pojazdu. Warto pamiętać, że nowe pojazdy tracą na wartości najszybciej w pierwszych latach użytkowania, a GAP pozwala zabezpieczyć tę różnicę.'
    },
  ],
  [
  {
      title: 'Ubezpieczenie samochodu w leasingu - czy jest obowiązkowe?',
      content: 'Jeśli korzystasz z samochodu w leasingu, musisz posiadać ubezpieczenia auta w leasingu obejmujące OC, AC oraz GAP. To gwarancja ochrony pojazdu, którego właścicielem formalnie pozostaje leasingodawca. Dzięki odpowiednim polisom leasingodawcy zapewniają sobie ochronę finansową na wypadek uszkodzenia pojazdu.'
    },
    {
      title: 'Jak działa ubezpieczenie auta w leasingu?',
      content: 'Ubezpieczenie samochodu w leasingu działa na podobnych zasadach, jak tradycyjne polisy. W razie szkody koszty naprawy są pokrywane z Twojego AC lub OC, a ubezpieczenie GAP chroni przed utratą wartości pojazdu. Polisy leasingowe są często negocjowane z myślą o korzystnych warunkach dla leasingobiorców, co pozwala na uzyskanie atrakcyjnych stawek.'
    },
  ],
  [
  {
      title: 'Ile kosztuje ubezpieczenie samochodu?',
      content: 'Koszt ubezpieczenia samochodu zależy od wielu czynników, takich jak <b>wartość pojazdu, wiek kierowcy czy historia ubezpieczeniowa.</b> Dodatkowo, czynniki takie jak miejsce zamieszkania, liczba przejeżdżanych kilometrów rocznie oraz rodzaj użytkowania pojazdu (np. prywatny czy służbowy) mogą wpływać na cenę składki.<br/><br/><b>Ile kosztuje ubezpieczenie samochodu dla młodego kierowcy?</b><br/><br/>Młodzi kierowcy mogą spodziewać się wyższych składek ze względu na brak historii ubezpieczeniowej. Niektórzy ubezpieczyciele oferują zniżki dla młodych kierowców, jeśli użytkują samochód wspólnie z bardziej doświadczonym właścicielem pojazdu.'
    },
  ],
  [
  {
      title: 'Czy można zmienić ubezpieczenie samochodu przed końcem okresu umowy?',
      content: 'Tak, posiadacze pojazdów mechanicznych mogą zmienić ubezpieczenie samochodu przed końcem okresu umowy w określonych przypadkach. Ważne jest, aby pamiętać, że procedura wymaga wcześniejszego wypowiedzenia umowy zgodnie z przepisami. Zmiana ubezpieczenia jest jednym z najczęściej zadawanych pytań kierowców, którzy chcą skorzystać z atrakcyjniejszych ofert lub lepszych warunków.'
    },
  ],
  [
  {
      sectionHeading: 'Ubezpieczenie samochodu z VEHIS',
      title: '',
      content: 'W VEHIS oferujemy kompleksowe rozwiązania ubezpieczeniowe od renomowanych zakładów ubezpieczeniowych. Zapewniają one pełną ochronę Twojego samochodu. Proponujemy <b>polisy OC, AC, NNW oraz atrakcyjne ubezpieczenie GAP,</b> dostosowane do Twoich potrzeb. Dodatkowo umożliwiamy rozłożenie kosztu ubezpieczenia na wygodne raty.  Dzięki temu możesz cieszyć się pełnym zakresem ochrony przy zachowaniu elastyczności finansowej.'
    },  
    {
      title: '',
      content: 'Nasze ubezpieczenia <b>nie wymagają udziału własnego,</b> co oznacza, że w przypadku szkody nie poniesiesz dodatkowych kosztów związanych z naprawą samochodu. Naprawy realizowane są w autoryzowanych serwisach oraz u naszych Partnerów Serwisowych z użyciem oryginalnych części, gwarantując najwyższą jakość obsługi. Dodatkowo, pakiety ubezpieczeniowe skonstruowane dla klientów VEHIS, <b>obowiązują w całej Europie,</b> co daje Ci pełną swobodę podróżowania.'
    },
    {
      title: '',
      content: 'Jednym z istotnych elementów naszej oferty jest <b>ubezpieczenie GAP,</b> które stanowi doskonałe uzupełnienie pakietu ubezpieczeniowego. W przypadku szkody całkowitej lub kradzieży polisa GAP pozwala odzyskać różnicę między odszkodowaniem z polisy komunikacyjnej, a wartością fakturową pojazdu z dnia zakupu, co oznacza, że możesz odzyskać nawet 100% wartości samochodu. Dodatkowo, klienci w VEHIS otrzymują <b>gwarancję stałej składki</b> przez cały okres finansowania, niezależnie od inflacji, wzrostu cen polis czy liczby napraw realizowanych z odszkodowania.'
    },
  ],
];

const faqData =  [
  {
    question: "Jakie ubezpieczenia komunikacyjne oferuje VEHIS?",
    answer:
      "Klientom VEHIS oferujemy ubezpieczenia komunikacyjne na wyjątkowo atrakcyjnych warunkach. Oprócz obowiązkowego OC oferujemy także: <ul class=\"list-disc pl-8\"><li>AutoCasco (AC),</li><li>ubezpieczenie NNW,</li><li>ubezpieczenie szyb samochodowych (PAS),</li><li>Zielona Karta,</li><li>dodatkowe rozszerzenie o usługi Assistance.</li></ul>",
  },
  {
    question: "Jakie są zalety ubezpieczenia komunikacyjnego w ofercie VEHIS?",
    answer:
      "Z naszej oferty możesz wybrać ubezpieczenie spośród ofert największych Towarzystw Ubezpieczeniowych - PZU, Ergo Hestia, Compensa, Benegia, Helvetia, AXA.<br/>Zalety naszej oferty komunikacyjnej: <ul class=\"list-disc pl-8\"><li>ubezpieczenie roczne lub pełne ze stałą wysokością składki przez okres umowy leasingowej,</li><li>gwarantowana obsługa przy wznowieniu polisy ubezpieczeniowej,</li><li>bezpłatny samochód zastępczy przez cały okres naprawy w przypadku szkody.</li></ul>",
  },
  {
    question: "Jakie są korzyści z ubezpieczenia pełnego (wieloletniego)?",
    answer:
      "Korzyści z takiego rozwiązania to przede wszystkim gwarancja stałego i niezmiennego kosztu ubezpieczenia pojazdu przez cały okres leasingu, niezależnie od ilości szkód w tym okresie oraz brak konieczności opłaty dodatkowej składki z tytułu wypłaty odszkodowania za szkodę. Kupując polisę do leasingu w cyklach corocznych jesteśmy więc zdani na sytuację rynkową i skoki cen ubezpieczeń. Przy 5-letnim okresie finansowania polisę należy wykupić 5 razy. Mając na uwadze średni wzrost cen ubezpieczeń o około 10% rocznie, ubezpieczenie pełne (wieloletnie) daje wyraźne oszczędności, zarówno pieniędzy, jak i czasu koniecznego na formalności związane z przedłużaniem ubezpieczenia co rok.",
  },
  {
    question:
      "Czym jest ubezpieczenie GAP i przed czym zabezpiecza?",
    answer:
      "Ubezpieczenie GAP (Guaranteed Asset Protection) jest przydatne dla osób finansujących zakup samochodu na kredyt lub leasing. Jego podstawowym zadaniem jest ochrona przed stratą finansową - ubezpieczenie GAP pokrywa różnicę między kwotą, którą otrzymasz od ubezpieczyciela w razie całkowitej szkody lub kradzieży samochodu, a pozostałą do spłaty kwotą kredytu lub leasingu. Dzięki temu nie zostaniesz z długiem w przypadku utraty pojazdu. Składka za ubezpieczenie GAP jest relatywnie niska w porównaniu do potencjalnej kwoty, którą można stracić bez tej ochrony. Samochody szybko tracą na wartości, zwłaszcza w pierwszych latach. Ubezpieczenie GAP zapewnia, że nie poniesiesz finansowych konsekwencji tego spadku wartości w razie szkody całkowitej.",
  },
  {
    question: "Jakie są wyłączenia z ubezpieczeń na terenie Rosji, Białorusi, Mołdawii i Ukrainy?",
    answer:
      "W związku z inwazją Rosji na Ukrainę uważnie analizujemy, jak ta sytuacja może wpływać na działalność i bezpieczeństwo naszych klientów oraz samą spółkę. W związku z tym na terytorium powyższych krajów Towarzystwa Ubezpieczeniowe nie biorą odpowiedzialności za:<ul class=\"list-disc pl-8\"><li>uszkodzenia lub utratę pojazdu wskutek działań wojennych,</li><li>obowiązkowe świadczenia na rzecz wojska,</li><li>konfiskaty, w tym również na rzecz armii.</li></ul>Dodatkowo ubezpieczyciele wycofali na terytorium Ukrainy, Rosji, Białorusi i Mołdawii ochronę w zakresie kradzieży pojazdu, jego części lub wyposażenia.<br/>Na obszarach objętych działaniami wojennymi nie jest możliwa także realizacja usług assistance.",
  },
]
const steps = [
  '<p class="mb-2 font-normal">Możesz wykupić</p> Na koniec umowy wpłać ustaloną na początku wartość wykupu i przejmij samochód na własność.',
  '<p class="mb-2 font-normal">Możesz zwrócić</p> Oddaj samochód do VEHIS bez spłaty wartości wykupu.',
  '<p class="mb-2 font-normal">Możesz przedłużyć umowę</p> Rozłóż wartość wykupu na dogodne raty i przedłuż z nami umowę.',
  '<p class="mb-2 font-normal">Możesz zmienić plany przed końcem umowy</p> W każdej chwili i bez podawania przyczyny możesz zwrócić samochód do VEHIS i na przykład wymienić go na inny model.'
];

const insurancePromoBoxesData = 
    [
        {
            title: 'Renomowani ubezpieczyciele',
            description: 'PZU, Ergo Hestia, Axa, Benefia.'
        },
        {
            title: 'Pakiet OC/AC/NNW',
            description: 'z gwarancją niezmienności składki przez wiele lat i możliwością rozłożenia na raty.'
        },
        {
            title: 'Ubezpieczenie GAP',
            description: 'pokrywające różnicę między odszkodowaniem z OC/AC a wartością samochodu z dnia zakupu.'
        },
    ];

   const featuresData =  [
      {
          title: 'Assistance na Polskę i Europę.',
          icon: drivePlusAssistanceIcon
      },
      {
          title: 'Serwisowanie samochodu z rabatami dzięki aplikacji mobilnej.',
          icon: drivePlusPhoneIcon
      },
      {
          title: 'Wsparcie w procesie obsługi i rozliczenia szkód komunikacyjnych.',
          icon: drivePlusSupportIcon
      },
      {
          title: 'GPS wraz z aplikacją - bezpieczeństwo i zarządzanie samochodem.',
          icon: drivePlusGpsIcon
      },
  ]
  const formOpened = ref(false);
const isMobileView = computed(() => lte('lg'));
const openForm = (event) => {
    if(isMobileView.value) {
      event.preventDefault();
      formOpened.value = true;
    } 
}

const heroBoxesData = [
            {
                heading: 'Pełne ubezpieczenie',
                txt: 'OC/AC/NNW w ratach bez prowizji.'
            },
            {
                heading: 'Brak udziału własnego',
                txt: 'w szkodzie.'
            },
            {
                heading: 'Ubezpieczenie GAP',
                txt: 'od ryzyka utraty wartości samochodu.'
            }
        ]
</script>

<template>
      <div class="sticky top-[97px] md:top-[111px] z-50">
        <Container no-paddings gray>
        <MenuScrolled>
          <AnchorJump>Ubezpieczenia VEHIS</AnchorJump>
          <AnchorJump to="benefits">Korzyści</AnchorJump>
          <AnchorJump to="insurance">Ochrona samochodu</AnchorJump>
          <AnchorJump to="faq">FAQ</AnchorJump>
          <AnchorJump to="contactSection">Zamów ofertę</AnchorJump>
        </MenuScrolled>
    </Container>
  </div>
  <Container noPaddings>
    <StaticMainHeader 
      bigAzureH1
      :heroBoxesData="heroBoxesData"
      :h1="'Ubezpieczenia VEHIS'">
      <template v-slot:mainImage>
        <img class="w-full xl:max-h-[312px] xl:object-contain" src="@/assets/images/statics/offer-insurance.jpg" alt="" />
      </template>
      <template v-slot:headerDescription>
        <p class="text-center lg:text-left text-2xl lg:text-3xl mt-12 text-coolGray-700 font-light">
          100% zabezpieczenia Twojego samochodu.
        </p>  
      </template>
    </StaticMainHeader>
  </Container>
    <div class="bg-cool-gray-light hidden lg:block">
      <div class="py-6">
        <FormContact analytics-form-type="offer-insurance" noContainer :customTextBtn="'Zamawiam kontakt'" :title="'Chcesz porozmawiać z naszym doradcą? Możesz to zrobić w każdym momencie'" standalone />
      </div>
    </div>
    <section class="pt-16 pb-16">
      <Container>
          <h2 class="mb-12 text-azure-dark text-4xl text-center">
            Oferujemy ubezpieczenia wyłącznie renomowanych towarzystw ubezpieczeniowych
          </h2>
          <div class="flex justify-center items-center gap-6">
              <VectorHestia/>
              <VectorAxa/>
              <VectorBenefia/>
              <VectorPzu/>
          </div>
          <ButtonCall customContent :show-working-hours="false" :azure="true">
              <div class="bg-azure font-light mt-12 rounded text-center mx-auto max-w-[186px] text-base text-white px-4 py-2 cursor-pointer">
                  Zapytaj o ofertę
              </div>
          </ButtonCall>
      </Container>
    </section>
    <div id="benefits" class="bg-dark-gray w-full mb-4">
        <Container>
            <div class="max-w-[948px] mx-auto text-white">
            <div class="flex mx-auto">
               <img :src="gte('sm') ? insuranceOfferDesktopImg : insuranceOfferMobileImg" alt="blackweek benefits"/>
            </div>
        </div>
        </Container>
    </div>
    <section id="insurance" class="my-[112px]">
    <Container noPaddings>
      <StaticInfoWithImage
        :title="'Ubezpieczenie pełnej wartości samochodu'" 
        :description="''" 
        :boxesData="insurancePromoBoxesData">
            <template v-slot:image>
              <picture>
                <source srcset="@/assets/images/statics/offer-entrepreneur-2.jpg" media="(min-width: 688px)" />
                <img class="w-full my-12" src="@/assets/images/statics/offer-entrepreneur-2-mobile.jpg" alt="" />
              </picture>
            </template>
        </StaticInfoWithImage>
      </Container>
      <Container noPaddings>
        <StaticInfoWithImage
        :title="'Ubezpieczenie tańsze o VAT'" 
        :description="'Koszt Twojej polisy obliczymy od wartości netto samochodu.'" 
        :boxesData="[]">
            <template v-slot:image>
              <picture>
                <source srcset="@/assets/images/statics/offer-insurance-2.jpg" media="(min-width: 688px)" />
                <img class="w-full my-12" src="@/assets/images/statics/offer-insurance-2-mobile.jpg" alt="" />
              </picture>
            </template>
        </StaticInfoWithImage>
      </Container>
    </section>
    <section id="faq">
        <StaticFaq :faqData="faqData"/>
      </section>
      <section id="contactSection" class="mb-20">
        <Container class="2xl:block">
          <FormContact :customFontWeight="'font-medium'" class="font-medium  class="font-medium  analytics-form-type="offer-insurance" title='Zamów ofertę! <p class="text-azure inline-block">Zostaw kontakt</p>' standalone />
      </Container>
      </section>
    <div class="bg-cool-gray-light">
      <Container>
        <SeoBrandsCloud noOuterPaddings textLeft/>
      </Container>
    </div>
    <Container class="mt-4">
      <h2 class="mx-auto text-center text-xl font-normal mb-6">
        Ubezpieczenie samochodu
      </h2>
      <p class="text-sm font-extralight mb-6">
        Ubezpieczenie auta to nie tylko konieczność wynikająca z przepisów prawa, ale przede wszystkim gwarancja spokoju w każdej sytuacji na drodze. Dzięki odpowiednio dobranej polisie możesz uniknąć poważnych kosztów związanych z wypadkami, kolizjami czy kradzieżą pojazdu.
      </p>
      <template v-for="(fieldItems, index) in fields" :key="`field-${index}`">
        <template v-if="fieldItems?.length > 0">
        <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
          <h2 v-if="fieldItems[0].sectionHeading" class="font-normal mb-2 text-lg">
              {{ fieldItems[0].sectionHeading }}
          </h2>
          <h2 class="font-normal mb-2 text-lg">{{ fieldItems.sectionHeading }}</h2>
          <div class="flex gap-6 flex-wrap md:flex-nowrap">
            <SeoContentBlock
              v-for="(item, idx) in fieldItems"
              :key="`field-${index}-${idx}`"
              :data="item"
              class="w-full"
            />
          </div>
        </template>
      </template>
      <p class="text-sm font-extralight mt-3">
        Dzięki <b>obliczaniu kosztu polisy od wartości netto samochodu</b> Twoje ubezpieczenie jest tańsze, co pozwala Ci zaoszczędzić, jednocześnie zapewniając pełen zakres ochrony. Wybierając VEHIS, zyskujesz <b>pewność i bezpieczeństwo na drodze,</b> a także atrakcyjne warunki finansowe.
      </p>
      <p class="text-sm font-extralight">Dołącz do grona zadowolonych klientów VEHIS i ciesz się spokojem oraz komfortem, wiedząc, że Twój samochód jest w pełni zabezpieczony.</p>
    </Container>
    <FormContact v-model:opened="formOpened" widthFull :customTextBtn="'Zamów kontakt'" docking analytics-form-type="offer-insurance" title='Zamów ofertę! <p class="text-azure inline-block">Zostaw kontakt!</p>' class="lg:hidden"/>
</template>
<style>
html {
  scroll-behavior: smooth;

}

#contactSection {
  scroll-margin-top: 150px;
}
</style>