<script setup>
import { onClickOutside } from '@vueuse/core'
import placeholderImg from '~/assets/images/statics/transparent_placeholder.png';
import { imaginChangeHost } from "~/utils/imagin";
import consultants from '~/configs/consultants.js'
const props = defineProps({
  header: String,
  subheader: String,
  car: Object,
  offer: Object,
  noButton: Boolean,
  noAdjust: Boolean,
  liczakView: Boolean
})
const route = useRoute();
const isUsedCar = props.car?.state !== 'Nowy';

const isLightbox = ref(false);
const modalGallery = ref(null);

const images = props.car?.images || [];

const carImage = computed(() => {
  const image = props.car.images[4] || props.car.images[1];
  return imaginChangeHost(image);
})

onClickOutside(modalGallery, event => isLightbox.value = false);

const isDigitalOrder = computed(() => {
  return route.name === 'transaction-form';
});
const isLiczakOffer = computed(() => {
  return route.name === 'external-offer';
});
onBeforeMount(() => {
  if (!this.car?.images?.[0]) {
    this.navigateTo('/');
  }
})
const isVehisConsultant = ref('');

onMounted(() => {
  consultants.forEach(consultant => {
    console.log('props.offer?.email === consultant.email', props.offer?.email === consultant.email);
    if(props.offer?.email === consultant.email ) isVehisConsultant.value = consultant.img
  });
})
</script>

<template>
  <div class="py-6">
    <template v-if="!isUsedCar || isLiczakOffer">
      <h1 class="text-2xl font-light">{{ header }}</h1>
      <h2 v-if="subheader" class="text-lg font-extralight">{{ subheader }}</h2>
      <div v-if="car?.group_id" class="text-sm font-extralight mt-2">ID {{ car?.group_id }}</div>
      <div 
        class="relative z-20 flex flex-col lg:flex-row items-center justify-center -mt-20 mb-4"
        :class="isLiczakOffer && isVehisConsultant? 'lg:h-[468px]' : 'lg:h-[368px]'"
      >
        <div
          :class="{'h-[650px] md:h-[750px]': isLiczakOffer && !isUsedCar}"
          class="absolute -mx-8 left-0 -bottom-10 right-0 h-[550px] md:h-[600px] lg:h-[140px] bg-gradient-to-t from-gray-100 via-gray-200 to-gray-400 z-0"></div>

        <div class="grow grid lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-center z-10 mt-0 items-center">
          <div class="lg:col-span-2 xl:col-span-3 flex justify-center pt-20 md:pt-32">
            <NuxtImg v-if="car?.images && car.images.length > 0 && (car.images[4] || car.images[1] !== '')" :src="carImage" fit="cover" class="w-[580px] lg:w-[700px]" />
            <img v-else :src="placeholderImg">
          </div>
        </div>
        <div :class="{'relative pt-28 sm:pt-18 md:pt-16': isLiczakOffer && !isUsedCar}"
             class="lg:absolute w-full lg:w-[300px] lg:min-w-[300px] right-0 -mb-8">
          <p v-if="isLiczakOffer && !isUsedCar" class="absolute -top-6 sm:-top-10 md:-top-14 text-xs font-extralight z-20 block lg:hidden">
            Prezentowana grafika samochodu ma jedynie charakter poglądowy.<br />
            Rzeczywisty wygląd, a w szczególności kolor i wersja wyposażenia mogą odbiegać od prezentowanego na grafice.
          </p>
          <CarOfferFloatingBox v-if="!liczakView" :car="props.car" class="block lg:hidden" noTop :noButton="noButton" :noAdjust="noAdjust" />
          <CarOfferFloatingBoxLiczak v-else :car="props.car" :offer="props.offer" class="block lg:hidden" noTop />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="grid grid-cols-12">
        <div class="col-span-12 mb-3 lg:mb-0 lg:col-span-2 xl:col-span-3">
          <h1 class="text-2xl font-normal">{{ header }}</h1>
          <h2 class="text-lg font-extralight">{{ subheader }}</h2>
        </div>
        <div class="col-span-12 lg:col-span-6">
          <div :class="isLightbox ? 'mx-auto  transition-[max-width] fixed inset-0 bg-white overflow-y-auto h-full w-full z-70' : ''">
            <div v-if="isLightbox" class="shadow-box py-6">
              <Container :no-paddings="true">
                <div class="w-full bg-white relative">
                  <div class="flex justify-between items-center">
                    <NuxtLink :to="{name:'index'}">
                      <VectorVehisHorizontal class="w-32 md:w-auto" />
                    </NuxtLink>
                  </div>
                  <div @click="isLightbox = false" class="cursor-pointer w-10 h-10 rounded bg-[#E5E7EB] flex items-center justify-center right-0 absolute top-0">
                    <IconCloseLight class="hover:w-8 h-8 transition-all" />
                  </div>
                </div>
              </Container>
            </div>
            <Container :outer-padding="'px-4 sm:px-0'" :no-paddings="true" class="px-0">
              <div class="flex justify-between mt-6 flex-wrap" v-if="isLightbox">
                <div>
                  <p class="text-2xl font-normal">{{ header }}</p>
                  <p class="text-lg font-extralight mb-6">{{ subheader }}</p>
                </div>
                <div class="flex items-end mb-6">
                  <div class="flex flex-col w-full items-end">
                    <p class="w-full font-extralight text-xs">
                      Twoja rata <EffectNetGrossIndicator/>
                    </p>
                    <p class="text-4xl font-normal text-azure mr-4 flex justify-center">
                      <EffectPrice :value="props.car?.price"/>
                      <span class="font-light text-sm flex items-end">zł</span>
                    </p>
                  </div>
                  <ButtonRounded class="min-w-[147px] lg:min-w-[236px]" color="azure" :href="{name: 'decide', params: {id: currentCar?.group_id}}">Pobierz ofertę</ButtonRounded>
                </div>
              </div>
              <div ref="modalGallery">
                <CarGallery :is-lightbox="isLightbox" @isLightbox="(v) => isLightbox = v.value" :photos="images" v-if="images.length  && !isDigitalOrder" do-not-cut />
                <NuxtImg :src="car?.images?.[0]" fit="cover" class="w-[580px] lg:w-[700px]" v-else-if="car?.images?.[0]" />
              </div>
              <div v-if="!isLightbox" class="relative lg:absolute w-full lg:w-[300px] lg:min-w-[300px] right-0 -mb-8">
                <CarOfferFloatingBox v-if="!liczakView" :offerView="true" :car="props.car" class="block lg:hidden" noTop :noButton="noButton" :noAdjust="noAdjust" />
                <CarOfferFloatingBoxLiczak v-else :car="props.car" :offer="props.offer" class="block lg:hidden" noTop />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
