<template>
    
<svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.1069 60.144C13.6376 60.144 0.240234 46.7466 0.240234 30.2773C0.240234 13.808 13.6376 0.410645 30.1069 0.410645C46.5762 0.410645 59.9736 13.808 59.9736 30.2773C59.9736 46.7466 46.5762 60.144 30.1069 60.144ZM30.1069 1.47731C14.2136 1.47731 1.3069 14.4053 1.3069 30.2773C1.3069 46.1493 14.2136 59.0773 30.1069 59.0773C46.0002 59.0773 58.9069 46.1707 58.9069 30.2773C58.9069 14.384 45.9789 1.47731 30.1069 1.47731Z" fill="#3B61AB"/>
<path d="M30.1076 57.0082C44.8706 57.0082 56.8383 45.0405 56.8383 30.2775C56.8383 15.5146 44.8706 3.54688 30.1076 3.54688C15.3447 3.54688 3.37695 15.5146 3.37695 30.2775C3.37695 45.0405 15.3447 57.0082 30.1076 57.0082Z" fill="url(#paint0_linear_8778_270131)"/>
<path d="M38.0864 49.4131H21.7664C20.5718 49.4131 19.6758 48.3464 19.6758 47.0451V13.5091C19.6758 12.2078 20.5931 11.1411 21.7664 11.1411H38.0864C39.2811 11.1411 40.1771 12.2078 40.1771 13.5091V47.0664C40.1771 48.3678 39.2598 49.4344 38.0864 49.4344V49.4131Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M19.6113 16.5171H40.198" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M19.6113 43.1836H40.198" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M28.0176 13.7227H31.7936" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.5554 46.1916C30.5554 46.5116 30.2781 46.7889 29.9154 46.7889C29.5527 46.7889 29.2754 46.5116 29.2754 46.1916C29.2754 45.8716 29.5527 45.5942 29.9154 45.5942C30.2781 45.5942 30.5554 45.8716 30.5554 46.1916Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M32.2188 33.7969L36.4854 38.4902" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.373 31.4292L36.741 34.1172" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.3306 31.2797C35.6746 28.9117 35.3546 25.8397 33.3706 23.8557C31.3866 21.8717 29.3386 21.701 27.1839 22.2984L30.5332 25.6477V27.8664L28.6559 29.7437H26.4372L23.0879 26.3944C22.4479 28.485 22.9172 30.917 24.6452 32.581C26.6292 34.565 29.7012 34.949 32.0692 33.541" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_8778_270131" x1="3.37695" y1="30.2775" x2="56.817" y2="30.2775" gradientUnits="userSpaceOnUse">
<stop stop-color="#65B1FF"/>
<stop offset="1"/>
</linearGradient>
</defs>
</svg>
</template>