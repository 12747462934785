<script setup>
import consumerOfferDesktopImg from '@/assets/images/statics/consumer-offer-benefits.jpg';
import consumerOfferMobileImg from '@/assets/images/statics/consumer-offer-benefits.jpg';
import drivePlusImg from '@/assets/images/statics/drive-plus.jpg';
import drivePlusAssistanceIcon from '@/components/Vector/DrivePlusAssistanceCircle.vue';
import drivePlusPhoneIcon from '@/components/Vector/DrivePlusPhoneCircle.vue';
import drivePlusSupportIcon from '@/components/Vector/DrivePlusSupportCircle.vue';
import drivePlusGpsIcon from '@/components/Vector/DrivePlusGpsCircle.vue';

useHead({
  title: "Leasing konsumencki samochodu - Oferta - Vehis.pl",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
  link: [
    {rel: 'canonical', href: `https://vehis.pl${useRoute().fullPath}`}
  ]

});

const {gte, lte} = useBreakpoints();

const backend = useBackend();

const slides = useSlides();

const refControl = ref();
const refSlideNavigation = ref();

onMounted(() => slides.initSlides(refControl, refSlideNavigation))
const fields = [
  [
    {
      title: 'Na czym polega leasing konsumencki?',
      content: 'Leasing konsumencki polega na tym, że firma leasingowa udostępnia samochód osobie prywatnej na określony czas, najczęściej na 2–5 lat, w zamian za miesięczną ratę leasingową. Co istotne, leasingobiorca nie staje się właścicielem pojazdu – to własność leasingodawcy, a korzystanie z auta regulowane jest umową leasingową. Po zakończeniu umowy leasingowej możliwa jest opcja wykupienia samochodu lub zwrotu pojazdu.<br/><br/>Raty leasingowe obejmują najczęściej koszty użytkowania auta, a także opłaty związane z ubezpieczeniem. Dla osób, które nie prowadzą działalności gospodarczej, leasing dla osoby prywatnej jest dogodnym sposobem na posiadanie nowoczesnego samochodu bez potrzeby jego zakupu. Dodatkowo, formalności związane z leasingiem konsumenckim są znacznie uproszczone w porównaniu do tradycyjnych kredytów, a sama procedura często wymaga jedynie dostarczenia dowodu osobistego oraz sprawdzenia historii kredytowej.'
    },
    {
      title: 'Leasing konsumencki samochodów — kiedy się na niego zdecydować?',
      content: 'Leasing konsumencki samochodów jest świetnym rozwiązaniem dla osób, które nie chcą zamrażać dużych środków finansowych w samochód, a jednocześnie cenią sobie elastyczność. Często leasing samochodowy dla osoby prywatnej okazuje się bardziej opłacalny niż zakup pojazdu na kredyt. Wynika to z możliwości wymiany samochodu po zakończeniu umowy na nowy model, co pozwala uniknąć utraty wartości auta. Leasing konsumencki może być dobrym wyborem także dla tych, którzy nie są pewni, czy chcą być właścicielem pojazdu po zakończeniu umowy – opcja zwrotu pojazdu lub jego wykupienia daje swobodę działania.<br/><br/>Jeśli zastanawiasz się, kiedy leasing konsumencki będzie korzystny, warto wziąć pod uwagę kilka kwestii. Przede wszystkim, jeśli cenisz sobie niskie koszty początkowe, a jednocześnie chcesz mieć dostęp do nowoczesnego, bezpiecznego pojazdu, leasing prywatny samochodu może być idealnym rozwiązaniem.'
    }
  ],
  [    {
      title: 'Jakie korzyści ma leasing konsumencki samochodu?',
      content: 'Leasing konsumencki samochodu to rozwiązanie, które niesie za sobą wiele korzyści.<br/><ol class="list-disc"><li>Jedną z najważniejszych jest brak konieczności posiadania dużych środków na starcie. W przypadku leasingu, opłata wstępna jest znacznie niższa niż wkład własny wymagany przy kredycie samochodowym, co sprawia, że leasing jest bardziej przystępnym dla osób prywatnych.</li><li>Inną zaletą leasingu konsumenckiego jest elastyczność – umowa leasingowa może być dostosowana do Twoich potrzeb pod względem długości trwania oraz wysokości miesięcznych rat</li><li>Warto też pamiętać, że leasing pozwala na wymianę samochodu po zakończeniu umowy na nowy model, co jest szczególnie atrakcyjne dla osób, które cenią sobie nowoczesne technologie i chcą regularnie korzystać z nowych pojazdów.</li><li>W przypadku leasingu nie musisz martwić się o utratę wartości samochodu – to ryzyko ponosi firma leasingowa.</li><li>Po zakończeniu umowy możesz zdecydować, czy chcesz wykupić auto, czy też wziąć nowe w leasing konsumencki. Dodatkowo, koszty leasingu często obejmują serwisowanie pojazdu, co oznacza mniej formalności i obowiązków dla leasingobiorcy.</li></ol>'
    }],
  [
    {
      title: 'Leasing samochodu dla osoby prywatnej z VEHIS — elastyczne i przejrzyste warunki',
      content: 'Leasing prywatny samochodu to świetna opcja dla klientów indywidualnych, którzy cenią sobie przejrzyste zasady i minimum formalności. VEHIS może zaoferować Ci atrakcyjne warunki leasingu dla osób prywatnych, z możliwością wyboru długości umowy oraz wysokości miesięcznej opłaty. Co więcej, gwarantujemy proste procedury, co oznacza, że badanie zdolności kredytowej jest szybkie i klarowne. Nie musisz martwić się o długotrwałe formalności – VEHIS dba o to, aby cały proces był jak najbardziej komfortowy dla klienta.',
    },
    {
      title: 'Duży wybór samochodów w leasing konsumencki i bogata oferta',
      content: 'Decydując się na leasing prywatny samochodu z VEHIS, masz dostęp do szerokiej gamy pojazdów osobowych, zarówno nowych, jak i używanych. Auta leasing konsumencki to nie tylko nowoczesne modele, ale także możliwość wyboru samochodów dostosowanych do Twojego stylu życia i potrzeb. Leasing samochodu używanego może być korzystnym rozwiązaniem dla osób, które nie potrzebują najnowszych modeli, ale chcą cieszyć się komfortem użytkowania pojazdu w doskonałym stanie technicznym.<br/><br/>Możemy zaoferować także różne opcje leasingowe – od krótkoterminowych po długoterminowe, co pozwala na pełną elastyczność i dopasowanie warunków umowy do Twoich preferencji. Jeśli chcesz wymienić auto po zakończeniu umowy, firma umożliwia łatwe przejście na nowy leasing konsumencki bez zbędnych formalności.',
    }
  ],
  [
    {
      title: 'Jak wziąć samochód w leasing na firmę z VEHIS?',
      content: 'VEHIS oferuje kompleksowe usługi leasingowe. Proces ubiegania się o leasing samochodowy jest prosty i szybki. Wystarczy wypełnić wniosek leasingowy, wybrać odpowiedni model auta oraz dostosować warunki umowy leasingowej do swoich potrzeb.<br/> </br>Po podpisaniu umowy leasingowej klient otrzymuje samochód i może od razu zacząć z niego korzystać. W czasie trwania umowy leasingu operacyjnego VEHIS oferuje również program wsparcia serwisowego, co dodatkowo zwiększa komfort użytkowania pojazdu. Na koniec finansowania podejmujesz decyzję, czy chcesz wykupić auto, czy też wymienić je na nowszy model w ramach nowej umowy.'
    }
  ],
  [
    {
      title: 'Leasing samochodowy dla osoby prywatnej od VEHIS',
      content: 'W VEHIS specjalizujemy się w leasingu konsumenckim, oferując elastyczne warunki oraz minimum formalności. Dzięki szerokiej ofercie pojazdów oraz wzorowemu podejściu do klienta, leasing konsumencki jest wygodnym i opłacalnym rozwiązaniem dla osób prywatnych.'
    }
  ]
];

const faqData =  [
  {
    question: "Czym jest produkt Samochód na raty?",
    answer:
      "Samochód na raty to doskonała oferta nabycia samochodu bez działalności gospodarczej, oparta o elastyczne rozwiązania leasingu konsumenckiego. Na czym on polega? Najprościej mówiąc, leasing konsumencki pozwala na użytkowanie samochodu przez określony czas w zamian za comiesięczne opłaty, jednocześnie bez potrzeby zakupu na własność samochodu.",
  },
  {
    question: "Dlaczego warto wybrać Samochód na raty?",
    answer:
      "Leasing samochodowy dla osoby prywatnej w ofercie Samochód na raty może być bardzo opłacalny, szczególnie jeśli nie planujesz być właścicielem pojazdu po zakończeniu umowy. Koszty leasingu są często niższe niż kredytu, a dodatkowo nie musisz martwić się o utratę wartości samochodu. Dodatkowo, po zakończeniu umowy masz możliwość wymiany auta na nowy model bez konieczności angażowania dużych środków finansowych.",
  },
  {
    question: "Jakie dokumenty będą potrzebne do złożenia wniosku o Samochód na raty?",
    answer:
      "Wystarczy, że wyślesz do nas prosty wniosek, w którym m.in. podasz nam dane z dokumentu tożsamości i wyrazisz zgodę na weryfikację w bazach (może być telefonicznie). Dodatkowo poprosimy Cię o przesłanie oświadczenia o miejscu zatrudnienia i dochodzie.",
  },
  {
    question:
      "Co jest lepsze, klasyczny kredyt czy Samochód na raty w leasingu konsumenckim?",
    answer:
      "To zależy od Twoich potrzeb i oczekiwań. Kredyt samochodowy wymaga większego zaangażowania finansowego na starcie, natomiast leasing konsumencki (w ofercie Samochód na raty) daje większą elastyczność i mniejsze koszty początkowe. W leasingu konsumenckim to firma leasingowa pozostaje właścicielem pojazdu, co oznacza, że po zakończeniu umowy masz możliwość zwrotu auta lub jego wykupu.",
  },
  {
    question: "Czy mogę zmienić termin płatności rat?",
    answer:
      "Tak, w trakcie trwania umowy leasingu konsumenckiego masz możliwość zmiany terminu płatności raty w ramach jednego miesiąca kalendarzowego. Nasze Biuro Obsługi Klienta jest do Twojej dyspozycji. Chętnie dostosujemy harmonogram płatności do Twoich potrzeb",
  },
  {
    question: "Czy mogę sfinansować samochód spoza oferty VEHIS?",
    answer:
      "Choć oferta VEHIS obejmuje wszystkie najpopularniejsze segmenty i większość marek dostępnych w Polsce to może się zdarzyć, że swój upragniony samochód znajdziesz u innego dostawcy. Oczywiście my nadal bardzo chętnie go sfinansujemy! W VEHIS finansujemy samochody osobowe i dostawcze do 3,5 t, nowe i używane do 5 lat z maksymalnym przebiegiem 200 tys. km. Samochód musi mieć dowód zakupu w postaci faktury VAT lub faktury VAT marża.",
  },
  {
    question: "Na czym polega możliwość wymiany lub zwrotu samochodu przed końcem umowy?",
    answer:
      "W ofercie samochodu na raty, w ramach umowy leasingu konsumenckiego wprowadziliśmy możliwość jej przedterminowego zakończenia bez podawania przyczyny. W dowolnym momencie możesz zgłosić do nas chęć rozliczenia i zakończenia umowy. Zestawimy wówczas Twoje zobowiązanie z prognozowaną wartością samochodu (wstępna wycena na bazie Eurotax). Jeżeli pozostała do spłaty kwota przewyższy wartość samochodu, to wpłacisz nam różnicę w formie kaucji i możesz oddać samochód do VEHIS. Od tego momentu nie będziemy wystawiali kolejnych faktur i wystawimy Twój samochód na sprzedaż. Ty też możesz pozyskać Klienta we własnym zakresie! Po sprzedaży nastąpi rozliczenie, w którym dodatkowo uwzględniamy zwrot z niewykorzystanej części ubezpieczenia. Jeżeli po finalnym rozliczeniu zostanie nadwyżka to w całości Ci ją wypłacimy. Jeżeli przychód ze sprzedaży i zwrot z ubezpieczenia nie pokryją wartości zobowiązania będziesz musiał dopłacić różnicę (w tym celu wykorzystamy kaucję, o której piszemy powyżej). Po zamknięciu całej transakcji możesz wziąć ponownie skorzystać z oferty Samochód na raty lub po prostu zakończyć naszą współpracę.",
  },{
      question: "Gdzie mogę odebrać samochód?",
      answer: "W naszej ofercie posiadamy samochody od dealerów z całej Polski. Znajdziemy dla Ciebie samochód z jak największym rabatem, więc może zdarzyć się, że będzie on pochodził z salonu mieszczącego się w oddalonej od Ciebie części kraju. Jeśli nie będziesz mógł odebrać go osobiście w salonie, zajmiemy się organizacją profesjonalnego transportu pod Twój dom."
    }
]
const steps = [
  '<p class="mb-2 font-normal">Możesz wykupić</p> Na koniec umowy wpłać ustaloną na początku wartość wykupu i przejmij samochód na własność.',
  '<p class="mb-2 font-normal">Możesz zwrócić</p> Oddaj samochód do VEHIS bez spłaty wartości wykupu.',
  '<p class="mb-2 font-normal">Możesz przedłużyć umowę</p> Rozłóż wartość wykupu na dogodne raty i przedłuż z nami umowę.',
  '<p class="mb-2 font-normal">Możesz zmienić plany przed końcem umowy</p> W każdej chwili i bez podawania przyczyny możesz zwrócić samochód do VEHIS i na przykład wymienić go na inny model.'
];

const insurancePromoBoxesData = 
    [
        {
            title: 'Renomowani ubezpieczyciele',
            description: 'PZU, Ergo Hestia, Axa, Benefia.'
        },
        {
            title: 'Pakiet OC/AC/NNW',
            description: 'z gwarancją niezmienności składki przez wiele lat i możliwością rozłożenia na raty.'
        },
        {
            title: 'Ubezpieczenie GAP',
            description: 'pokrywające różnicę między odszkodowaniem z OC/AC a wartością samochodu z dnia zakupu.'
        },
    ];

   const featuresData =  [
      {
          title: 'Assistance na Polskę i Europę.',
          icon: drivePlusAssistanceIcon
      },
      {
          title: 'Serwisowanie samochodu z rabatami dzięki aplikacji mobilnej.',
          icon: drivePlusPhoneIcon
      },
      {
          title: 'Wsparcie w procesie obsługi i rozliczenia szkód komunikacyjnych.',
          icon: drivePlusSupportIcon
      },
      {
          title: 'GPS wraz z aplikacją - bezpieczeństwo i zarządzanie samochodem.',
          icon: drivePlusGpsIcon
      },
  ]
  const formOpened = ref(false);
const isMobileView = computed(() => lte('lg'));
const openForm = (event) => {
    if(isMobileView.value) {
      event.preventDefault();
      formOpened.value = true;
    } 
}
</script>

<template>
      <div class="sticky top-[97px] md:top-[111px] z-50">
    <Container no-paddings gray>
      <MenuScrolled>
        <AnchorJump>Samochód na raty</AnchorJump>
        <AnchorJump to="how-to-work">Jak działa</AnchorJump>
        <AnchorJump to="benefits">Korzyści</AnchorJump>
        <AnchorJump to="drive-plus">Pakiet DRIVE+</AnchorJump>
        <AnchorJump to="insurance">Ubezpieczenie</AnchorJump>
        <AnchorJump to="faq">FAQ</AnchorJump>
        <AnchorJump to="contactSection">Zamów ofertę</AnchorJump>
      </MenuScrolled>
    </Container>
  </div>
  <Container noPaddings>
    <StaticMainHeader 
      :h1="'Leasing konsumencki'" 
      :h2="'Samochód na raty'" >
      <template v-slot:mainImage>
        <img class="w-full" src="@/assets/images/statics/samochod-na-raty-tiguan.png" alt="" />
      </template>
      <template v-slot:headerDescription>
        <p class="text-center font-light lg:text-left text-2xl lg:text-3xl mt-12 text-coolGray-700">
            Doskonała oferta nabycia samochodu <span class="whitespace-nowrap">bez działalności</span> gospodarczej.
        </p>  
      </template>
    </StaticMainHeader>
  </Container>
    <div class="bg-cool-gray-light hidden lg:block">
      <div class="py-6">
        <FormContact noContainer :customTextBtn="'Zamawiam kontakt'" :title="'Chcesz porozmawiać z naszym doradcą? Możesz to zrobić w każdym momencie'" standalone />
      </div>
    </div>
    <div class="pt-16">
      <Container>
        <SeoBrandsSlider/>
      </Container>
    </div>
    <section id="how-to-work">
      <Container>
        <StaticInfoWithImage>
            <template v-slot:image>
              <img class="w-full my-12" src="@/assets/images/statics/offer-consumer-1.jpg" alt="" />
              <img src="" alt="">
            </template>
        </StaticInfoWithImage>
    </Container>
  </section>
    <StaticInfoOverlay 
    />
    <section id="benefits">
      <Container>
        <img class="mx-auto my-16" :src="gte('sm') ? consumerOfferDesktopImg : consumerOfferMobileImg" alt="blackweek benefits"/>
      </Container>
    </section>
  <section id="drive-plus">
    <Container>
      <StaticImageWithFeatures :featuresData="featuresData" :mainPhoto="{src: drivePlusImg, alt: 'drive plus img'}" />
    </Container>
  </section>
  <section id="insurance">
    <Container>
      <StaticInfoWithImage
        :title="'Ubezpieczenie tańsze o VAT'" 
        :description="'Koszt Twojej polisy obliczymy od wartości netto samochodu.'" 
        :boxesData="insurancePromoBoxesData">
            <template v-slot:image>
              <picture>
                <source srcset="@/assets/images/statics/offer-insurance-2.jpg" media="(min-width: 688px)" />
                <img class="w-full my-12" src="@/assets/images/statics/offer-insurance-2-mobile.jpg" alt="" />
              </picture>
            </template>
        </StaticInfoWithImage>
      </Container>
    </section>
    <section class="bg-cool-gray-light mb-[112px]" id="how-to-start">
    <Container>
      <BlockHowTo
        hideArrows
        noSelected
        :negMargin="false"
        textLeft 
        :textCenter="false"  
        :title="'Zakończenie umowy? Masz czas na decyzję!'"
        hideDescription
        :steps="steps"
        :customHeadingClass="'text-3xl text-azure-dark text-center'"
        >
        <template v-slot:headerDescription>
          <p class="mt-6 text-center text-xl text-cool-gray-700 max-w-4xl mx-auto">
            Nie zastanawiaj się nad tym, co wydarzy się za kilka lat. W ofercie samochodu na raty o sposobie zakończenia umowy zdecydujesz we właściwym dla Ciebie momencie.
          </p>
        </template>
        </BlockHowTo>
    </Container>
  </section>
    <section id="faq">
        <StaticFaq :faqData="faqData"/>
      </section>
      <section id="contactSection" class="mb-20">
        <Container class="2xl:block">
          <FormContact :customFontWeight="'font-medium'" class="font-medium  class="font-medium  analytics-form-type="offer-consumer" title='Zamów ofertę! <p class="text-azure inline-block">Zostaw kontakt</p>' standalone />
      </Container>
      </section>
    <div class="bg-cool-gray-light">
      <Container>
        <SeoBrandsCloud noOuterPaddings textLeft/>
      </Container>
    </div>
    <Container class="mt-4">
      <h2 class="mx-auto text-center text-lg font-normal mb-6">
      Samochód na raty w leasingu konsumenckim
      </h2>
      <p class="text-sm font-extralight mb-6">
        Leasing konsumencki, choć kojarzony głównie z przedsiębiorcami, staje się coraz popularniejszy wśród klientów indywidualnych. Na czym polega to rozwiązanie? Najprościej mówiąc, leasing konsumencki pozwala na użytkowanie samochodu przez określony czas w zamian za comiesięczne opłaty, bez konieczności jego zakupu na własność. To atrakcyjna alternatywa dla kredytu samochodowego, zwłaszcza dla osób, które nie chcą angażować dużych środków finansowych na starcie.
      </p>
      <template v-for="(fieldItems, index) in fields" :key="`field-${index}`">
        <template v-if="fieldItems?.length > 0">
        <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
          <div class="flex gap-6 flex-wrap md:flex-nowrap">
            <SeoContentBlock
              v-for="(item, idx) in fieldItems"
              :key="`field-${index}-${idx}`"
              :data="item"
              class="w-full"
            />
          </div>
        </template>
      </template>
    </Container>
    <FormContact v-model:opened="formOpened" widthFull :customTextBtn="'Zamów kontakt'" docking analytics-form-type="rp-zarabiaj-1%" title='Zamów ofertę!<p class="text-azure inline-block">Zostaw kontakt!</p>' class="lg:hidden"/>
</template>
<style>
html {
  scroll-behavior: smooth;

}

#contactSection {
  scroll-margin-top: 150px;
}
</style>