<script setup>
const props = defineProps({
    disableControlClass: {
    type: Boolean,
    default: false
  }
})
const refSlide = ref(null);
const scroll =  (direction) => {
    const step = refSlide.value.firstElementChild.offsetWidth + 24;

    refSlide.value.scrollBy({
        top: 0,
        left: step * (direction === "right" ? 1 : -1),
        behavior: "smooth"
    });
}
</script>

<template>
    <div class="flex shrink-0 items-center">
        <div class="w-16 shrink-0">
            <div :class="{ 'control': !disableControlClass }" @click.stop="scroll('left')" @dblclick.stop="">
                <IconChevronLeft class="scale-75 opacity-80 cursor-pointer" />
            </div>
        </div>
        <div class="grow shrink basis-auto overflow-x-hidden snap-x snap-mandatory flex gap-6 p-4" ref="refSlide">
            <slot />
        </div>
        <div class="w-16 shrink-0 flex justify-end">
            <div :class="{ 'control': !disableControlClass }"@click.stop="scroll('right')" @dblclick.stop="">
                <IconChevronRight class="ml-1 scale-75 opacity-80 cursor-pointer" />
            </div>
        </div>
    </div>
</template>

<style lang="postcss" scoped>
.control {
  @apply w-9 h-9 rounded-full border border-black/60 flex justify-center items-center cursor-pointer hover:bg-gray-400 transition-all;
}
</style>
