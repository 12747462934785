export const useSiteConfig = () => {

  const contactPhone = "+48 22 103 39 74";
  const contactPhoneSec = "+48 22 104 92 05";
  const rpContactPhone = "+48 729 087 800 "
  const bokPhone = "+48 32 308 84 44";
  const contactEmail = "bok@vehis.pl";

  const workingHours = {
    start: "9:00",
    end: "21:00"
  }

  return {
    contactPhone,
    contactPhoneSec,
    rpContactPhone,
    contactEmail,
    workingHours,
    bokPhone
  }
}
