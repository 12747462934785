<script setup>
const analytics = useAnalytics();
const props = defineProps({
    item: {
        type: Object,
        default: {}
    }, 
    position: {
        type: String,
        default: ''
    }
});

const isAdvert = computed(() => !!props.item?.ad_link)
const nuxtLinkSrc = computed(() => isAdvert.value ? props.item?.ad_link : `/moto/artykul/${props.item?.slug}`)
</script>
<template> 
    <NuxtLink v-if="!isAdvert" :to="nuxtLinkSrc" class="flex gap-3">
        <NuxtImg class="w-[100px] h-[100px] object-cover rounded-lg" :src="item?.main_photo"/>
        <div>
            <p class="text-dark-red text-sm font-semibold mb-1">{{ item?.category?.name }}</p>
            <p :title="item?.title" class="text-base font-normal mb-1 line-clamp-2">
            {{ item?.title }}
            </p>
            <p class="text-sm font-extralight">{{ item?.publication_date }}</p>
        </div>
    </NuxtLink>
  
    <NuxtLink
      v-else
      :to="nuxtLinkSrc"
            @click="analytics.analyticsLayer({
                event: 'select_promotion',
                creative_name: item?.title ? item?.title : '',
                type: props.position,
                slot: 'Reklama',
                url: nuxtLinkSrc
            })"
            class="flex gap-3"
    >
      <NuxtImg class="w-[100px] h-[100px] object-cover rounded-lg" :src="item?.main_photo"/>
      <div>
        <p class="text-dark-red text-sm font-semibold mb-1">{{ item?.category?.name }}</p>
        <p class="text-base font-normal mb-1">
          {{ item?.title }}</p>
        <p class="text-sm font-extralight">{{ item?.publication_date }}</p>
      </div>
    </NuxtLink>
</template> 