export default defineNuxtPlugin(() => {

  Map.prototype.getKeyByValue = function (searchValue) {
    for (let [key, value] of this.entries()) {
      if (value === searchValue) {
        return key;
      }
    }
  }

  Object.removeKey = function (object, key) {
    return Object
      .keys(object)
      .filter(objKey => objKey !== key)
      .reduce((newObj, newKey) => {
        newObj[newKey] = object[newKey];
        return newObj;
      }, {});
  }

  Object.getKeysByValue = function (object, value) {
    const result = [];
    Object.keys(object).forEach(key => {
      if (object[key] && object[key].toLowerCase() === value.toLowerCase()) result.push(key)
    });
    return result;
  }

  Array.prototype.addOrRemove = function (value, addOrRemove = true) {
    if (addOrRemove && !this.find(i => i === value)) {
      this.push(value);
    }
    if (!addOrRemove && this.find(i => i === value)) {
      const idx = this.indexOf(value);
      if (idx !== -1) {
        this.splice(idx, 1);
      }
    }
  }

  Array.prototype.toggle = function (value) {
    this.addOrRemove(value, !!!this.find(i => i === value))
  }

  Array.prototype.toNameValueArrayOfObjects = function (upperCaseFirst = false) {
    const arr = []
    this.forEach(i => {
      if(upperCaseFirst) i = i.upperCaseFirst();
      arr.push({name: i, value: i})
    });
    return arr;
  }

  Array.prototype.unique = function () {
    return this.filter((v, i, a) => a.indexOf(v) === i);
  }

  Array.prototype.toMap = function(valuesAsKey = false, keyModCallback = null, valueModCallback = null) {
    const map = new Map();
    this.forEach((v,k) => {
      if(valuesAsKey) k = v;
      if(keyModCallback) k = keyModCallback(k);
      if(valueModCallback) v = valueModCallback(v);
      map.set(k, v);
    })
    return map;
  }

  Map.prototype.getFilteredMapByKeysArray = function(arr) {
    const filteredMap = new Map
    this.forEach((e, key) => {
      if(arr.indexOf(key) !== -1) {
        filteredMap.set(key, key)
      }
    })

    return filteredMap;

    // const filteredYears = new Map
    // productionYearOptions.value.forEach((e, key) => {
    //   if (availableProductionYears.indexOf(key) !== -1) {
    //     filteredYears.set(key, key);
    //   }
    // });
    // productionYearOptions.value = filteredYears;
  }

  Map.prototype.valuesByKeys = function (arrayOfKeys) {
    const result = [];
    arrayOfKeys.forEach(key => {
      const value = this.get(key);
      if (value) result.push(value)
    })
    return result;
  }

  String.prototype.toLowerDashed = function () {
    return this.toLowerCase().replace(/ /g, '-');
  }

  String.prototype.upperCaseFirst = function () {
    const value = this.toLowerCase();
    const parts = value.split("-").map(v => v.substring(0,1).toUpperCase() + v.substring(1));
    return parts.join("-");
  }

})
